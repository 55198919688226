import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
import axios from "axios";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
function SizeMaster() {
  const [showModal, setShowModal] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [sizeview, setSizeview] = useState("");
  const [newSizeName, setNewSizeName] = useState("");
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [categoryid, setCategoryid] = useState("");
  const [category, setCategory] = useState([]);

  const [selectedcategory, setSelectedcategory] = useState([]);
  console.log({ selectedcategory: selectedcategory });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const Navigate = useNavigate();
  const [categoryid1, setCategoryid1] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [editSize, setEditSize] = useState({
    id: "",
    size: "",
    category_id: "",
  });
  const sizeNameInputRef = useRef(null);

  const fetchSizes = () => {
    return fetch("https://mahalakshmimobiles.com/admin/size_master.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch sizes");
        }
        return response.json();
      })
      .then((data) => {
        setSizes(data);
      })
      .catch((error) => {
        console.error("Error fetching sizes:", error);
      });
  };

  useEffect(() => {
    if (showModal) {
      sizeNameInputRef.current.focus();
    }
    fetchCategories();
  }, [showModal]);
  useEffect(() => {
    setLoading(true);
    fetchSizes()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setNewSizeName("");
    setEditShowModal(false);
    setShowModal(false);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  useEffect(() => {
    const result = sizes.filter((size) => {
      const categoryNameMatch = size.size
        .toLowerCase()
        .includes(query.toLowerCase());
      return categoryNameMatch;
    });
    setFilter(result);
  }, [query, sizes]);
  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const getCategoryLabels = (category_id) => {
    const categoryIds =
      typeof category_id === "string" ? category_id.split(",") : [category_id];
    return category
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const getCategoryLabels1 = (categoryValue) => {
    if (!categoryValue) return [];

    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",")
        : categoryValue;
    return category.filter((item) =>
      categoryIds.includes(item.value.toString())
    );
  };
  const handleProductSelect1 = (selectedOptions) => {
    setEditSize((prev) => ({
      ...prev,
      category_id: selectedOptions.map((option) => option.value).join(","),
    }));
  };
  const handleProductSelect = (selectedOptions) => {
    const productIds = selectedOptions.map((item) => item.value);
    const productvalue = selectedOptions.map((item) => item.label);
    console.log({ productIds: productvalue });
    setSelectedcategory(selectedOptions);
    setCategoryid(productvalue);
    setCategoryid1(productIds);

    console.log({ object: productIds });
  };
  const fetchCategories = () => {
    return fetch("https://mahalakshmimobiles.com/admin/categoriesmaster.php")
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
        setCategory(options);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const res = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await res.json();
      setSizeview(data.permission.SizeMasterView);
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handleInsert = () => {
    if (!newSizeName.trim()) {
      swal("warning", "Please enter size name", "warning");
      return;
    }
    // if (categoryid1.length === 0) {
    //   swal("Warning", "Please select at least one category", "warning");
    //   return;
    // }
    const formData = new FormData();
    formData.append("size", newSizeName);
    // formData.append("category_id", categoryid1);
    fetch("https://mahalakshmimobiles.com/admin/size_master.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the response from the server
        fetchSizes(); // Fetch the updated list of sizes
        setShowModal(false); // Close the modal
        setNewSizeName("");
        // setCategoryid([]); // Clear category id field
        // setSelectedcategory([]); // Clear selected category field
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handelEdit = async (id) => {
    // console.log("id", id);
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/size_master.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        setNewSizeName(categoryDetails.size);
        setEditSize(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = () => {
    if (!editSize.size.trim()) {
      swal("warning", "Please enter size name", "warning");
      return;
    }
    const data = {
      id: editSize.id,
      size: editSize.size,
      category_id: editSize.category_id,
    };

    fetch(`https://mahalakshmimobiles.com/admin/size_master.php?id=${editSize.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the response from the server
        fetchSizes(); // Fetch the updated list of sizes
        setEditShowModal(false); // Close the edit modal
        setNewSizeName("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this size master!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/size_master.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Operating system deleted successfully!");
          fetchSizes(); // Refresh the list after deleting
        } else {
          console.error("Failed to delete size master");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [colour, setcolour] = useState([]);
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = colour.filter((capacity) =>
      capacity.size.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [colour, query]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
    setcolour(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setcolour(sizes);
  }, [sizes]);

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Size Master</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Size Master
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {sizeview.add === true ? (
                  <button
                    className="add-category-button "
                    onClick={handleShowModal}
                  >
                    Add Size Master
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {sizeview.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Size Master
              </button>
            ) : (
              ""
            )} */}
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Size</th> */}
                    <th
                      onClick={() => handleSortByColumn("size")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Size
                      {sortColumn === "size" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th>Category Name</th> */}
                    {sizeview.edit === true || sizeview.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((size, index) => (
                      <tr key={size.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>{size.size}</td>
                        {/* <td>{getCategoryLabels(size.category_id)}</td> */}
                        {(sizeview.edit || sizeview.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {sizeview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handelEdit(size.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {sizeview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(size.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={sizeview.edit || sizeview.delete ? 4 : 3}
                        style={{ textAlign: "center" }}
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <header className="modal-header">
              <h5 className="modal-title">Add Size</h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div>
                <label className="bold">
                  Size Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Size Name"
                  value={newSizeName}
                  ref={sizeNameInputRef}
                  onChange={(e) => setNewSizeName(e.target.value)}
                />
                {/* <label className="bold">Category</label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Category "
                  options={category}
                  value={selectedcategory}
                  isMulti
                  onChange={handleProductSelect}
                /> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleInsert}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={EditshowModal} onHide={handleCloseModal}>
            <header className="modal-header">
              <h5 className="modal-title">Edit Size</h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={() => handleCloseModal()}
              >
                ×
              </button>
            </header>
            <Modal.Body>
              <div>
                <label className="bold">
                  Size Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  value={editSize.size}
                  onChange={(e) =>
                    setEditSize({ ...editSize, size: e.target.value })
                  }
                  placeholder="Enter Size Name"
                />
                {/* <label className="bold">Category</label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Category "
                  options={category}
                  value={getCategoryLabels1(editSize.category_id)}
                  isMulti
                  onChange={handleProductSelect1}
                /> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                Update
              </Button>
              <Button variant="secondary" onClick={() => handleCloseModal()}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SizeMaster;
