import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
function VariantMaster() {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [variantview, setvariantView] = useState("");
  const [variants, setVariants] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [editVariant, setEditVariant] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const variantNameInputRef = useRef(null);
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredVariants(variants);
    } else {
      const filtered = variants.filter((variant) =>
        variant.variant_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVariants(filtered);
    }
  }, [searchQuery, variants]);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditModal(false);
    setEditVariant(null);
    setVariantName("");
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const sortedFilter = [...filteredVariants].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleEditModal = (variant) => {
    setEditModal(true);
    setEditVariant(variant);
    setVariantName(variant.variant_name);
  };

  const Getdata = async () => {
    const data = await fetch("https://mahalakshmimobiles.com/admin/variantmaster.php?");
    const response = await data.json();
    setVariants(response);
    setFilteredVariants(response);
  };
  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setvariantView(data.permission.VariantMasterView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const handleInsert = async () => {
    if (!variantName.trim()) {
      swal("warning", "Please enter variant name", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("variant_name", variantName);

    fetch("https://mahalakshmimobiles.com/admin/variantmaster.php?", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        Getdata(); // Refresh the variants after inserting
        // Clear the fields
        setVariantName("");
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdate = async () => {
    try {
      if (!variantName.trim()) {
        swal("warning", "Please enter variant name", "warning");
        return;
      }
      const data = {
        id: editVariant.id,
        variant_name: variantName,
      };

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/variantmaster.php?id=${editVariant.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        console.log("Variant updated successfully!");

        handleCloseModal();
        Getdata();
      } else {
        console.error("Failed to update variant");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (showModal) {
      variantNameInputRef.current.focus();
    }
  }, [showModal]);

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/variantmaster.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Variant deleted successfully!");
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [variant, setVariant] = useState([]);

  const handleSortByName = () => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.variant_name.localeCompare(b.variant_name);
      } else {
        return b.variant_name.localeCompare(a.variant_name);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {

    setVariant(variants)
  }, [variants]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) =>
      capacity.variant_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Variant Master</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Variant Master
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left">


          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
             <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>

            <div className="d-flex align-items-center ms-auto">
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
              {variantview.add && (
                <button className="add-category-button" onClick={handleShowModal}>
                  Add Variant Master
                </button>
              )}
              </div>
              
            </div>
          </div>


          <div className="table-container text-end mt-3">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Variant Name</th> */}
                    <th onClick={() => handleSortByName()} style={{ cursor: "pointer", textAlign: "center" }}>
                      Variant  Name
                      {sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )}
                    </th>
                    {variantview.edit === true ||
                      variantview.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((variant, index) => (
                      <tr key={variant.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {variant.variant_name}
                        </td>
                        {(variantview.edit || variantview.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {variantview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEditModal(variant)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {variantview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(variant.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={variantview.edit || variantview.delete ? 3 : 2}
                        style={{ textAlign: "center" }}
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${page === currentPage ? "active" : ""
                  }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Add Variant Master</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <label className="bold">Variant Name <i className="text-danger">*</i></label>
            <input
              className="form-control"
              placeholder="Enter Variant Name"
              value={variantName}
              ref={variantNameInputRef}
              onChange={(e) => {
                const inputValue = e.target.value.toLowerCase();
                if (/^[a-z\s]*$/.test(inputValue)) {
                  setVariantName(inputValue);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Edit Variant</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <Modal.Body>
          <div>
            <label className="bold">Variant Name <i className="text-danger">*</i></label>
            <input
              className="form-control"
              value={variantName}
              onChange={(e) => {
                const inputValue = e.target.value;
                const lowercaseValue = inputValue.toLowerCase();

                if (/^[a-z]*$/.test(lowercaseValue)) {
                  setVariantName(lowercaseValue);
                }
              }}
              placeholder="Enter Variant Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VariantMaster;
