import React, { useEffect, useState } from "react";
import "./Orderlist.css";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../Global/Api";
import Lottie from "react-lottie";

function Orderlist() {
  const Navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  console.log(",orderList",orderList);
  
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const callApi = new Api();
  const [ordereye, setOrderseyes] = useState([]);
  useEffect(() => {
    callApi
      .adminOrderList({})
      .then((res) => {
        setOrderList(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching order list:", error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleSortByColumn = (column) => {
    const sortedOrders = [...orderList].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : "";
      const valueB = b[column] ? b[column].toString() : "";

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setOrderList(sortedOrders);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const filteredOrders = orderList.filter((order) =>
    order.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = filteredOrders.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationNumbers.push(i);
    }
    return paginationNumbers;
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Process the fetched data

      setOrderseyes(data.permission.OrdersView.vieworders);
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Order List</h3>
      </div>
      <div className="card-cat">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <label className="me-2">Show</label>
            <select
              style={{ height: "35px" }}
              className="form-select m-0 "
              value={itemsPerPage}
              onChange={handlePerPageChange}
            >
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="80">80</option>
            </select>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleSearch}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>
        <div className="table-container text-end">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.No</th>
                  {/* <th style={{ textAlign: "center" }}>Order No</th> */}
                  <th
                    onClick={() => handleSortByColumn("order_id")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order No
                    {sortColumn === "order_id" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>

                  {/* <th style={{ textAlign: "center" }}>Name</th> */}
                  <th
                    onClick={() => handleSortByColumn("user_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Name
                    {sortColumn === "user_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Mobile No</th> */}
                  <th
                    onClick={() => handleSortByColumn("user_phone")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Mobile No
                    {sortColumn === "user_phone" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Address</th> */}
                  <th
                    onClick={() => handleSortByColumn("address_details")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Address
                    {sortColumn === "address_details" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("order_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Date
                    {sortColumn === "order_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Price</th> */}
                  <th
                    onClick={() => handleSortByColumn("total_price")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Price
                    {sortColumn === "total_price" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  {ordereye === true ? (
                    <th style={{ textAlign: "center" }}>More Details</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((order, index) => (
                    <tr key={order.order_id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>{order.order_id}</td>
                      <td style={{ textAlign: "center" }}>{order.user_name}</td>
                      <td style={{ textAlign: "center" }}>
                        {order.user_phone}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {order.address_details}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {order.order_date}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {order.total_price}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {order.order_status}
                      </td>
                      
                      {ordereye && (
                        <td style={{ textAlign: "center" }}>
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              Navigate("/vieworder", {
                                state: {
                                  order_id: order.order_id,
                                  user_id: order.user_id,
                                  address_id: order.address_id,
                                },
                              });
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
    </div>
  );
}

export default Orderlist;
