import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaTrash } from "react-icons/fa";
import swal from "sweetalert";
import Lottie from "react-lottie";
function MobileUsers() {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [mobileusersView, setMobileusersView] = useState("");
  const [users, setUsers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/appuser.php?"
      );
      const data = await response.json();
      setUsers(data);
      setFilter(data);
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    const result = users.filter((user) => {
      const nameMatch =
        user.user_name &&
        user.user_name.toLowerCase().includes(searchQuery.toLowerCase());
      const phoneMatch =
        user.user_phone &&
        user.user_phone
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      return nameMatch || phoneMatch;
    });
    setFilter(result);
  }, [searchQuery, users]);

  const handleDelete = async (userId) => {
    try {
      const shouldUpdate = await swal({
        title: "Are you sure?",
        text: "Once updated, this user will be deactivated!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
  
      if (shouldUpdate) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/appuser.php?user_id=${userId}&user_status=1`,
          {
            method: "PUT", // Change to GET method
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.ok) {
          swal("Updated!", "User has been deactivated.", "success");
          fetchData(); // Refresh the data after the update
        } else {
          console.error("Failed to update user");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMobileusersView(data.permission.MobileView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filter.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Mobile Users</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a onClick={() => navigate("/dashboard")}>Dashboard</a>
              </li>
              <li aria-current="page" className="breadcrumb-item active">
                Mobile Users
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>
        <div className="table-container text-end mt-3">
        {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>S.No</th>
                <th style={{ textAlign: "center" }}>User Name</th>
                <th style={{ textAlign: "center" }}>Email</th>
                <th style={{ textAlign: "center" }}>Mobile No</th>
                {mobileusersView.delete && (
                  <th style={{ textAlign: "center" }}>Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((user, index) => (
                  <tr key={user.user_id}>
                    <td style={{ textAlign: "center" }}>
                      {startIndex + index + 1}
                    </td>
                    <td style={{ textAlign: "center" }}>{user.user_name}</td>
                    <td style={{ textAlign: "center" }}>{user.user_mail}</td>
                    <td style={{ textAlign: "center" }}>{user.user_phone}</td>
                    {mobileusersView.delete && (
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="btn-delete"
                          onClick={() => handleDelete(user.user_id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    )}
                  </tr>
                 ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobileUsers;
