import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import "./Orderreport.css";
import { Button } from "react-bootstrap";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Orderreport() {
  const [showModal, setShowModal] = useState(false);
  const [orders, setOrders] = useState([]);
  // console.log({ orders: orders });
  const [fromDate, setFromDate] = useState("");
  // console.log({fromDate:fromDate})
  const [toDate, setToDate] = useState("");
  const [selectedOrder, setSelectedOrder] = useState([]);
  // console.log({ selectedOrder: selectedOrder });
  const [customerSearch, setCustomerSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [addressDetails, setAddressDetails] = useState([]);

  // console.log({ addressDetails: addressDetails });
  const [currentPage, setCurrentPage] = useState(1);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDetails = (orderId, addressId) => {
    fetchAddressDetails(addressId); // This fetches address details asynchronously
    console.log(addressId); // This logs the addressId, which might be useful for debugging

    fetch(
      `https://mahalakshmimobiles.com/admin/order_product_details.php?orderid=${orderId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedOrder(data); // Sets selected order data
        setShowModal(true); // Shows modal with order details
      })
      .catch((error) => console.error("Error fetching order details:", error));
  };

  const fetchAddressDetails = async (addressId) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/address.php?id=${addressId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch address details");
      }
      const data = await response.json();

      setAddressDetails(data); // Sets address details somewhere in your application state
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split("-").map(Number);

    // Ensure month and day are always two digits
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedDay}-${formattedMonth}-${year}`; // Return the date in "DD-MM-YYYY" format
  }

  useEffect(() => {
    if (fromDate && toDate) {
      // Convert the dates to "DD-MM-YYYY" format
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      const apiUrl = `https://mahalakshmimobiles.com/admin/orderreport.php?from_date=${formattedFromDate}&to_date=${formattedToDate}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          setOrders(data);
          // Fetch address details for each order
          data.forEach((order) => {
            fetchAddressDetails(order.address_id);
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setOrders([]);
        });
    }
  }, [fromDate, toDate]);

  const handleSearch = (e) => {
    setCustomerSearch(e.target.value);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.user_name.toLowerCase().includes(customerSearch.toLowerCase()) ||
      order.user_phone.includes(customerSearch)
    // Add more fields for searching as needed
  );

  const sortedFilter = [...filteredOrders].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(orders);
  }, [orders]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.user_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(customerSearch.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, customerSearch]);
  return (
    <div className="main-container mt-3">
      <div className="">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Order Reports</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Order Reports
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card">
          <div className="cat-left"></div>

          <div className="search-left-pro">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
            <div className="left-content">
              <div className="h6">
                <h6>From Date</h6>
                <div className="input-container">
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="h6">
                <h6>To Date</h6>
                <div className="input-container">
                  <input
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search Delivery Boy"
              />
              <FaSearch className="search-icon" />
            </div>

            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search Customer"
                value={customerSearch}
                onChange={handleSearch}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row"></div>
          </div>
          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO</th>

                  <th
                    onClick={() => handleSortByColumn("order_place_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Date and Time
                    {sortColumn === "order_place_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("Order No")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Phone Resolution
                    {sortColumn === "Order No" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("mode_of_payment")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Mode Of Payment
                    {sortColumn === "mode_of_payment" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("user_phone")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Customer Mob.No
                    {sortColumn === "user_phone" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                 

                  <th
                    onClick={() => handleSortByColumn("user_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Customer Name
                    {sortColumn === "user_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Customer Address</th>

                  <th
                    onClick={() => handleSortByColumn("order_time")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Date
                    {sortColumn === "order_time" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("order_accepted_time")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Accepted Date
                    {sortColumn === "order_accepted_time" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("order_packed_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Pickup Date
                    {sortColumn === "order_packed_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("order_delivered_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Delivery Time
                    {sortColumn === "order_delivered_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("tips")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Tips
                    {sortColumn === "tips" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("delivery_boy_id")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Delivery Boy Id
                    {sortColumn === "delivery_boy_id" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("delivery_boy_mobile_no")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Delivery Boy Mob.No
                    {sortColumn === "delivery_boy_mobile_no" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("delivery_charge")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Delivery Charge
                    {sortColumn === "delivery_charge" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("sub_total")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Sub Total
                    {sortColumn === "sub_total" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("discount")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Discount
                    {sortColumn === "discount" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("total_price")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Item Total Price
                    {sortColumn === "total_price" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>More Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((order, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td>{order.order_place_date}</td>
                      <td>{order.id}</td>
                      <td>{order.mode_of_payment}</td>
                      <td>{order.user_phone}</td>
                      {/* <td>{order.user_id}</td> */}
                      <td>{order.user_name}</td>
                      <td>
                        {Array.isArray(order.addressDetails) &&
                          order.addressDetails.map((address, index) => (
                            <p key={index}>
                              {address.flat_no}, {address.land_mark},{" "}
                              {address.pincode}, {address.state},{" "}
                              {address.street}, {address.town}
                            </p>
                          ))}
                      </td>
                      <td>{order.order_place_date}</td>
                      <td>{order.order_conformed_date}</td>
                      <td>{order.order_packed_date}</td>
                      <td>{order.order_delivered_date}</td>
                      <td>{order.tips}</td>
                      <td>{order.delivery_boy_id}</td>
                      <td>{order.delivery_boy_mobile_no}</td>
                      <td>{order.delivery_charge}</td>
                      <td>{order.sub_total}</td>
                      <td>{order.discount}</td>
                      <td>{order.total_price}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="primary"
                          onClick={() =>
                            handleDetails(order.id, order.address_id)
                          }
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="20" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Populate modal body with order details */}
          {selectedOrder &&
            selectedOrder.map((order, index) => (
              <div key={index}>
                <p>Order No: {order.order_id}</p>
                <p>Product Name: {order.product_name}</p>
                <p>Product Qty: {order.ProductQtytemQty} </p>
                <p>Product Amount: {order.product_amt} </p>
                <p>Product Tax: {order.product_tax} </p>
                <p>Product Discount: {order.product_discount} </p>
                <p>Size: {order.size} </p>
                <p>Color: {order.color} </p>
                <p>Product Total Price: {order.ProductTotalPrice} </p>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Orderreport;
