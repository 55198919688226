import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Managehomeslider.css";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Lottie from "react-lottie";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
function Managehomeslider() {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [homeslider, setHomeslider] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slidertype, setSlidertype] = useState("");
  const [manageHomeView, setManageHomeView] = useState("");
  const [slidername, setSlidername] = useState("");
  const [sliderimage, setsliderImage] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [status, setStatus] = useState("1");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [editCategory, setEditCategory] = useState({
    id: "",
    slider_name: "",
    slider_type: "",
    slider_image: "",
    status: "",
  });
  const [filename, setFilename] = useState("");
  const Navigate = useNavigate();
  const [imageFile, setImageFile] = useState(null);

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];

  // };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 140 * 1024; // 140KB in bytes

    if (selectedImage && selectedImage.size < maxSize) {
      setsliderImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSlidertype("");
    setSlidername("");
    setsliderImage("");
  };

  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editCategory.slider_image);
  }, []);

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleInsert = () => {
    if (!slidertype || !slidername || !sliderimage || !status) {
      alert("Error: One or more required fields are null or empty.");
      return;
    }

    const formData = new FormData();
    formData.append("slider_type", slidertype);
    formData.append("slider_name", slidername);
    formData.append("slider_image", sliderimage);
    formData.append("status", status);

    fetch("https://mahalakshmimobiles.com/admin/homeslider.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
        // Clear the data after insert
        setSlidertype("");
        setSlidername("");
        setsliderImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowModal(false);
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setManageHomeView(data.permission.HomeView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  useEffect(() => {
    const result = homeslider.filter((category) => {
      if (!category.slider_name) {
        return false;
      }

      return category.slider_name.toLowerCase().includes(query.toLowerCase());
    });
    setFilter(result);
  }, [query, homeslider]);

  const GetData = async () => {
    const data = await fetch("https://mahalakshmimobiles.com/admin/homeslider.php");
    const response = await data.json();
    setHomeslider(response);
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleDelete = async (homeslidersId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this homeslider!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/homeslider.php?id=${homeslidersId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setHomeslider(
            homeslider.filter((homesliders) => homesliders.id !== homeslidersId)
          );
          swal("Deleted!", "homeslider has been deleted.", "success");
        } else {
          console.error("Failed to delete homeslider");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelEdit = async (homeid) => {
    // console.log("id", id);
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/homeslider.php?id=${homeid}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === homeid);
        // console.log("data", categoryDetails);
        setEditCategory(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  //   setImageFile(file);
  // };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const minSize = 140 * 1024; // 140KB in bytes
    if (file && file.size < minSize) {
      setImageFile(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
      alert("Please select a file smaller than 140KB");
    }
  };

  const handleUpdate = async () => {
    // Validate required fields
    if (
      !editCategory.slider_type ||
      !editCategory.slider_name ||
      !editCategory.status
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    // Create FormData
    const formData = new FormData();
    formData.append("id", editCategory.id);
    formData.append("slider_type", editCategory.slider_type);
    formData.append("slider_name", editCategory.slider_name);

    if (imageFile) {
      formData.append("slider_image", imageFile);
    }

    formData.append("current_image", editCategory.image);
    formData.append("status", editCategory.status);

    try {
      // Perform fetch request
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/homesliderupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");

        // Update homeslider state
        setHomeslider((prevHomeslider) =>
          prevHomeslider.map((category) =>
            category.id === editCategory.id ? { ...editCategory } : category
          )
        );

        // Close modal
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
        alert("Failed to update category. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    } finally {
      // Fetch data again
      GetData();
    }
  };

  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(homeslider);
  }, [homeslider]);
  const [variant, setVariant] = useState([]);
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter(
      (capacity) =>
        capacity.slider_name &&
        capacity.slider_type.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, query]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Manage Home Slider Images</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Manage Home Slider Images
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="cat-left"></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {manageHomeView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add Manage Home Slider Images
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {manageHomeView.add === true ? (
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add Manage Home Slider Images
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <div className="table-container text-end mt-3">
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Name</th> */}
                    <th
                      onClick={() => handleSortByColumn("slider_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Name
                      {sortColumn === "slider_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Type</th> */}
                    <th
                      onClick={() => handleSortByColumn("slider_type")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Type
                      {sortColumn === "slider_type" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ textAlign: "center" }}>Image</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    {manageHomeView.edit === true ||
                    manageHomeView.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie && filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((homesliders, index) => (
                      <tr key={homesliders.id}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>
                          {homesliders.slider_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {homesliders.slider_type}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={`https://mahalakshmimobiles.com/admin/image/homeslider/${homesliders.slider_image}`}
                            width={40}
                            height={40}
                            alt={homesliders.slider_name}
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {homesliders.status === "1" ? (
                            <span className="active-item">Active</span>
                          ) : (
                            <span className="inactive-item">In-Active</span>
                          )}
                        </td>
                        {(manageHomeView.edit || manageHomeView.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {manageHomeView.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handelEdit(homesliders.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {manageHomeView.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(homesliders.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Home Slider Image</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>
                Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Name"
                value={slidername}
                onChange={(e) => setSlidername(e.target.value)}
              />
              <label>
                Type <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Type"
                value={slidertype}
                onChange={(e) => setSlidertype(e.target.value)}
              />

              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setStatus(newValue);
                    console.log(`Checkbox value: ${newValue}`);
                  }}
                />
              </div>
              <label>
                Image <i className="text-danger">*</i>
              </label>
              <p>Please choose square image less 140kb</p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Home Slider Image</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>
                Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Name"
                value={editCategory.slider_name}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    slider_name: e.target.value,
                  })
                }
              />
              <label>
                Type <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Type"
                value={editCategory.slider_type}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    slider_type: e.target.value,
                  })
                }
              />

              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    editCategory.status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={editCategory.status === "1"}
                  onChange={(e) => {
                    const newStatus = e.target.checked ? "1" : "0";
                    setEditCategory({
                      ...editCategory,
                      status: newStatus,
                    });
                    setSelectedOption("0");
                  }}
                />
              </div>

              <label>
                Image <i className="text-danger">*</i>
              </label>
              <p>Please choose square image less 140kb</p>
              <input
                type="file"
                accept="image/*"
                className="form-control m-1"
                onChange={(e) => {
                  handleFileInputChange(e);
                }}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <input
                value={editCategory.slider_image}
                type=" hidden "
                name="current_image"
                className="form-control m-1"
              />
              {/* <span id="file-name">{filename}</span> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Managehomeslider;
