import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./Stock.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Stock() {
  const [stock, setStock] = useState([]);
  console.log({ stock: stock });
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (productId) => {
    fetch(`https://mahalakshmimobiles.com/admin/variant.php?id=${productId}`)
      .then((response) => response.json())
      .then((data) => {
        setModalContent(data);
        setShowModal(true);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const navigate = useNavigate();

  const fetchStock = async () => {
    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/stock.php");
      const data = await response.json();
      if (data) {
        setStock(data);
        // console.log({data:data})
      } else {
        console.error("Categories data is undefined:", data);
        setStock([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchStock()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filteredStock.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const filteredStock = stock.filter(
    (item) =>
      item.product_name &&
      item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredStock.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredStock.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(stock);
  }, [stock]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.product_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Stock</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Stock
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
              </div>
            </div>
            <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Product Name</th> */}
                    <th
                      onClick={() => handleSortByColumn("product_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Product Name
                      {sortColumn === "product_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Product Stock</th> */}
                    <th
                      onClick={() => handleSortByColumn("variant_stock")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Product Stock
                      {sortColumn === "variant_stock" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Price</th> */}

                    <th
                      onClick={() => handleSortByColumn("variant_stock")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Colour
                      {sortColumn === "variant_stock" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th
                      onClick={() => handleSortByColumn("variant_stock")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Storage
                      {sortColumn === "variant_stock" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th
                      onClick={() => handleSortByColumn("variant_stock")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Variant Size
                      {sortColumn === "variant_stock" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th
                      onClick={() => handleSortByColumn("price")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Price
                      {sortColumn === "price" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ width: "4%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((item, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.product_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.variant_stock}
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {item.var_details?.colour || "No Colour Specified"}
                        {/* {item.Colour} */}
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {/* {item.Storage} */}
                        {item.var_details?.storage || "No Storage Specified"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {/* {item.Variant_Size} */}
                        {item.var_details?.variant_size || "No Size Specified"}
                        </td>


                        <td style={{ textAlign: "center" }}>{item.price}</td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn-delete"
                            onClick={() => handleOpenModal(item.variant_id)}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Product Details</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            {modalContent && modalContent.length > 0 ? (
              modalContent.map((variant, index) => (
                <div key={index} className="variant-details">
                  <p>
                    <span className="bold">Variant Size: </span>
                    {variant.variant_size}
                  </p>
                  <p>
                    <span className="bold">Price:</span> {variant.price}
                  </p>
                  {/* <p>
                    <span className="bold">Stock:</span> {variant.variant_stock}
                  </p> */}
                  <p>
                    <span className="bold">Colour:</span> {variant.colour}
                  </p>
                  <p>
                    <span className="bold">Storage:</span> {variant.storage}
                  </p>
                  <p>
                    <span className="bold">Size:</span> {variant.variant_size}
                  </p>
                  <p>
                    <span className="bold">Discount Price:</span>
                    {variant.discount_price}
                  </p>
                  <p>
                    <span className="bold">Variant Status:</span>{" "}
                    {variant.variant_status === "1" ? (
                      <span>Active</span>
                    ) : (
                      <span>In-Active</span>
                    )}
                  </p>
                  <div className="variant-images">
                    {variant.variant_image &&
                      JSON.parse(variant.variant_image).map(
                        (image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={`https://mahalakshmimobiles.com/admin/image/variant/${image}`}
                            alt={`Variant ${imgIndex + 1}`}
                            className="variant-image"
                          />
                        )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <p>No variants available...!</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Stock;
