import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
function Manageimage() {
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [offerImage, setOfferImage] = useState(null);
  const [position, setPosition] = useState("");
  const [manageimageView, setManageimageView] = useState("");
  const [sectionPosition, setSectionPosition] = useState("");
  const [images, setImages] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [editData, setEditData] = useState({
    id: "",
    position: "",
    section_position: "",
    offer_image: "",
  });
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    GetData();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setOfferImage(null);
    setPosition("");
    setSectionPosition("");
  };

  const filteredImages = images.filter((img) =>
    img.position.toLowerCase().includes(query.toLowerCase())
  );

  const handleInsert = () => {
    // if (!offerImage || !position || !sectionPosition) {
    //   alert("Please fill in all required fields.");
    //   return;
    // }

    const formData = new FormData();
    formData.append("offer_image", offerImage);
    formData.append("position", position);
    formData.append("section_position", sectionPosition);

    console.log("FormData contents:");
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    fetch("https://mahalakshmimobiles.com/admin/offerimage.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Insert response:", data);
        if (data.message === "Missing parameters") {
          console.error("Server Error: Missing parameters.");
        } else {
          console.log("Insert success:", data);
          GetData();
          handleCloseModal();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setManageimageView(data.permission.OfferimgView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const handleDelete = async (imageId) => {
    // Show a confirmation alert
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    // Proceed with deletion if confirmed
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/offerimage.php?id=${imageId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setImages(images.filter((img) => img.id !== imageId));
          Swal.fire("Deleted!", "Your image has been deleted.", "success");
        } else {
          Swal.fire("Error!", "Failed to delete image.", "error");
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire(
          "Error!",
          "An error occurred while deleting the image.",
          "error"
        );
      }
    }
  };

  const GetData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/offerimage.php"
      );
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = async (imageId) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/offerimage.php?id=${imageId}`
      );
      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === imageId);
        setEditData(categoryDetails);
        setFilename(categoryDetails.offer_image);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch image");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setOfferImage(selectedFile);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filteredImages.length / newItemsPerPage);
    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const sortedFilter = [...filteredImages].sort((a, b) => b.id - a.id);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = sortedFilter.slice(startIndex, endIndex);
  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);
  const visiblePages = 3;

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleInput = (e) => {
    setQuery(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editData.id);
      formData.append("position", editData.position);
      formData.append("section_position", editData.section_position);
      formData.append("offer_image", imageFile);
      formData.append("current_image", editData.offer_image);

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/offerimageupdate.php?id=${editData.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Image updated successfully!");
        setImages(
          images.map((img) => (img.id === editData.id ? editData : img))
        );
        setEditShowModal(false);
        GetData(); // Refresh data
      } else {
        console.error("Failed to update image");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  return (
    <div className="main-container mt-3">
      <div>
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Offer Images</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>
                <li aria-current="page" className="breadcrumb-item active">
                  Offer Images
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat">
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div> */}

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>

              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {manageimageView.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add offer
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="table-container text-end mt-3">
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>ID</th>
                  <th style={{ textAlign: "center" }}>Image</th>
                  <th style={{ textAlign: "center" }}>Position</th>
                  <th style={{ textAlign: "center" }}>Section Position</th>
                  {manageimageView.edit === true ||
                  manageimageView.delete === true ? (
                    <th style={{ textAlign: "center" }}>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((img, index) => (
                  <tr key={img.id}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={`https://mahalakshmimobiles.com/admin/image/manageofferimage/${img.offer_image}`}
                        width={40}
                        height={40}
                        alt="Offer"
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>{img.position}</td>
                    <td style={{ textAlign: "center" }}>
                      {img.section_position}
                    </td>
                    {manageimageView.edit === true ||
                    manageimageView.delete === true ? (
                      <td style={{ textAlign: "center" }}>
                        {manageimageView.edit === true ? (
                          <button
                            className="btn-edit"
                            onClick={() => handleEdit(img.id)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                        ) : (
                          ""
                        )}
                        {manageimageView.delete === true ? (
                          <button
                            className="btn-delete"
                            onClick={() => handleDelete(img.id)}
                          >
                            <FaTrash />
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Insert Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add New Offers Images here</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <p>
                Offer Image <i className="text-danger">*</i>
              </p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              <label>
                Position <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option value="">Select Position</option>
                <option value="top">Top</option>
                <option value="middle">Middle</option>
                <option value="bottom">Bottom</option>
              </select>
              <label>
                Section Position <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={sectionPosition}
                onChange={(e) => setSectionPosition(e.target.value)}
              >
                <option value="">Select Position</option>
                <option value="top">Top</option>
                <option value="middle">Middle</option>
                <option value="bottom">Bottom</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Offer Image</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label htmlFor="offer-image">
                Offer Image <i className="text-danger">*</i>
              </label>
              <input
                id="offer-image"
                type="file"
                className="form-control"
                accept=".jpg, .jpeg, .png"
                style={{ width: "100%" }}
                onChange={handleFileInputChange}
              />
              <span id="file-name">{filename}</span>
              <br />
              <label htmlFor="position" style={{ marginTop: "15px" }}>
                Position <i className="text-danger">*</i>
              </label>
              <select
                id="position"
                className="form-select"
                value={editData.position}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    position: e.target.value,
                  })
                }
              >
                <option value="">Select Position</option>
                <option value="top">Top</option>
                <option value="middle">Middle</option>
                <option value="bottom">Bottom</option>
              </select>
              <label htmlFor="section-position" style={{ marginTop: "15px" }}>
                Section Position <i className="text-danger">*</i>
              </label>
              <select
                id="section-position"
                className="form-select"
                value={editData.section_position}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    section_position: e.target.value,
                  })
                }
              >
                <option value="">Select Position</option>
                <option value="top">Top</option>
                <option value="middle">Middle</option>
                <option value="bottom">Bottom</option>
              </select>
              <input
                value={editData.offer_image}
                type="hidden"
                name="current_image"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Manageimage;
