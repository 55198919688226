import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

function Shopmaster() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState([]);
  const [shopView, setShopView] = useState("");
  const [newShop, setNewShop] = useState("");
  const [newGst, setNewGst] = useState("");
  const [newLogo, setNewLogo] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageFile, setImageFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [editShop, setEditShop] = useState({
    id: "",
    shop_name: "",
    gst: "",
    logo: "",
    email: "",
    phoneno: "",
  });
  const [practice, setPractice] = useState([]);
  console.log({ practice: practice });
  const [editShowModal, setEditShowModal] = useState(false);
  const shopNameInputRef = useRef(null);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewShop("");
    setNewGst("");
    setNewLogo(null);
    setNewEmail("");
    setNewPhone("");
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const fetchData = async () => {
    const response = await fetch("https://mahalakshmimobiles.com/admin/shop.php");
    const data = await response.json();
    setPractice(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getData = async () => {
    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/shop.php");
      const data = await response.json();
      setShop(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editShop.logo);
  }, []);

  useEffect(() => {
    if (showModal) {
      shopNameInputRef.current.focus();
    }
  }, [showModal]);
  const handleInsert = async () => {
    // Email validation regex (allowing caps)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    // Phone number validation regex (allowing digits only)
    const phoneRegex = /^\d{10}$/;
    // GST validation regex (exactly 15 characters)
    const gstRegex = /^.{15}$/;

    // Check for valid inputs
    if (
      !newShop.trim() ||
      !newGst.trim() ||
      !emailRegex.test(newEmail) ||
      !phoneRegex.test(newPhone) ||
      !gstRegex.test(newGst) // Validate GST
    ) {
      swal("Warning", "Please fill in all fields with valid data", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("shop_name", newShop);
    formData.append("gst", newGst);
    formData.append("logo", newLogo);
    formData.append("email", newEmail);
    formData.append("phoneno", newPhone);

    console.log("Form Data:", formData);

    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/shop.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Insert successful");
        getData(); // Refresh the data
        handleCloseModal(); // Close the modal
      } else {
        console.error("Failed to insert data. Status:", response.status);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const handleEditModal = (id, shop_name, gst, logo, email, phoneno) => {
    setEditShop({ id, shop_name, gst, logo, email, phoneno });
    setEditShowModal(true);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleUpdate = async () => {
    // Email validation regex (allowing caps)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    // Phone number validation regex (allowing digits only)
    const phoneRegex = /^\d{10}$/;
    // GST validation regex (exactly 15 characters)
    const gstRegex = /^.{15}$/;

    let errors = [];

    // Validate shop_name
    if (!editShop.shop_name.trim()) {
      errors.push("Shop name is required.");
    }

    // Validate GST
    if (!gstRegex.test(editShop.gst)) {
      errors.push("GST should have exactly 15 characters.");
    }

    // Validate email
    if (!emailRegex.test(editShop.email)) {
      errors.push("Please enter a valid email address.");
    }

    // Validate phone number
    if (!phoneRegex.test(editShop.phoneno)) {
      errors.push("Phone number should be exactly 10 digits.");
    }

    // Validate logo
    if (!editShop.logo) {
      errors.push("Logo image is required.");
    }

    if (errors.length > 0) {
      // Show all validation errors at once
      swal("Validation Errors", errors.join(" "), "warning");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("shop_name", editShop.shop_name);
      formData.append("gst", editShop.gst);
      formData.append("logo", imageFile);
      formData.append("current_image", editShop.logo);
      formData.append("email", editShop.email);
      formData.append("phoneno", editShop.phoneno);

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/shopupdate.php?id=${editShop.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Shop updated successfully!");
        getData(); // Refresh the data after updating
        setShop(
          shop.map((category) =>
            category.id === editShop.id ? editShop : category
          )
        );
        setEditShowModal(false); // Close the edit modal
      } else {
        console.error("Failed to update shop");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this shop!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/shop.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Shop deleted successfully!");
          getData(); // Refresh the data after deleting
        } else {
          console.error("Failed to delete shop");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setShopView(data.permission.ShopView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const filteredShops = shop.filter((item) =>
    item.shop_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredShops].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const [sortColumn, setSortColumn] = useState("");
  const [variant, setVariant] = useState([]);

  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(shop);
  }, [shop]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) =>
      capacity.phoneno.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Shop</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Shop
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="cat-left"></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>

          {shopView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add Shop
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {shopView.add === true ? (
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add Shop
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO</th>
                  {/* <th style={{ textAlign: "center" }}>Shop Name</th>
                  <th style={{ textAlign: "center" }}>GST</th>
                  <th style={{ textAlign: "center" }}>Logo</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Phone No</th> */}
                  <th
                    onClick={() => handleSortByColumn("shop_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Shop Name
                    {sortColumn === "shop_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>{" "}
                  <th
                    onClick={() => handleSortByColumn("gst")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    GST
                    {sortColumn === "gst" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Logo</th>
                  <th
                    onClick={() => handleSortByColumn("email")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Email
                    {sortColumn === "email" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("phoneno")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Phone No
                    {sortColumn === "phoneno" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {shopView.edit === true || shopView.delete === true ? (
                    <th style={{ textAlign: "center" }}>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.shop_name}</td>
                      <td style={{ textAlign: "center" }}>{item.gst}</td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={`https://mahalakshmimobiles.com/admin/image/shop/${item.logo}`}
                          alt={item.shop_name}
                          width={40}
                          height={40}
                          style={{ margin: "10px" }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>{item.email}</td>
                      <td style={{ textAlign: "center" }}>{item.phoneno}</td>
                      {shopView.edit === true || shopView.delete === true ? (
                        <td style={{ textAlign: "center" }}>
                          <div>
                            {shopView.edit === true && (
                              <button
                                className="btn-edit"
                                onClick={() =>
                                  handleEditModal(
                                    item.id,
                                    item.shop_name,
                                    item.gst,
                                    item.logo,
                                    item.email,
                                    item.phoneno
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {shopView.delete === true && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(item.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="bold">
              Shop Name <i className="text-danger">*</i>
            </label>
            <input
              type="text"
              placeholder="Enter the Shop Name"
              className="form-control"
              ref={shopNameInputRef}
              value={newShop}
              onChange={(e) => setNewShop(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="bold">
              GST <i className="text-danger">*</i>
            </label>
            <input
              type="text"
              placeholder="Enter the GST"
              className="form-control"
              value={newGst}
              onChange={(e) => setNewGst(e.target.value)}
              required
              maxLength="15"
            />
          </div>
          <div className="form-group">
            <label className="bold">
              Logo <i className="text-danger">*</i>
            </label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setNewLogo(e.target.files[0])}
              required
            />
          </div>
          <div className="form-group">
            <label className="bold">
              Email <i className="text-danger">*</i>
            </label>
            <input
              type="email"
              className="form-control"
              value={newEmail}
              placeholder="Enter the Email"
              onChange={(e) => setNewEmail(e.target.value)}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              required
            />
          </div>
          <div className="form-group">
            <label className="bold">
              Phone No <i className="text-danger">*</i>
            </label>
            <input
              type="tel"
              className="form-control"
              value={newPhone}
              placeholder="Enter the Phone No"
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                if (input.length <= 10) {
                  // Allow only 10 or fewer digits
                  setNewPhone(input);
                }
              }}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editShowModal}
        onHide={() => setEditShowModal(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>
              Shop Name <i className="text-danger">*</i>
            </label>
            <input
              type="text"
              className="form-control"
              value={editShop.shop_name}
              onChange={(e) =>
                setEditShop({ ...editShop, shop_name: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>
              GST <i className="text-danger">*</i>
            </label>
            <input
              type="text"
              className="form-control"
              maxLength="15"
              value={editShop.gst}
              onChange={(e) =>
                setEditShop({ ...editShop, gst: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>
              Logo <i className="text-danger">*</i>
            </label>
            <input
              className="form-control mb-2 "
              type="file"
              name="file2"
              id="myFile2"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                handleFileInputChange(e);
              }}
            />
            <input
              className="form-control"
              value={editShop.logo}
              type=" hidden "
              name="current_image"
            />
            <span id="file-name" hidden>
              {filename}
            </span>
          </div>
          <div className="form-group">
            <label>
              Email <i className="text-danger">*</i>
            </label>
            <input
              type="email"
              className="form-control"
              value={editShop.email}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              onChange={(e) =>
                setEditShop({ ...editShop, email: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>
              Phone No <i className="text-danger">*</i>
            </label>
            <input
              type="tel"
              className="form-control"
              value={editShop.phoneno}
              maxLength={10}
              onChange={(e) => {
                const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                if (input.length <= 10) {
                  // Allow only 10 or fewer digits
                  setEditShop({ ...editShop, phoneno: e.target.value });
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Shopmaster;
