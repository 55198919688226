import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
function Shootingmodes() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [shootingmodes, setShootingmodes] = useState([]);
  const [newShootingMode, setNewShootingMode] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const shootingmodesNameInputRef = useRef(null);
  const [categoryid, setCategoryid] = useState("");
  const [categoryid1, setCategoryid1] = useState("");
  const [shootingView, setShootingView] = useState("");
  const [category, setCategory] = useState([]);
  console.log({ category: category });
  const [selectedcategory, setSelectedcategory] = useState([]);
  console.log({ selectedcategory: selectedcategory });
  const [editShootingMode, setEditShootingMode] = useState({
    id: "",
    shooting_modes: "",
  });
  const [editShowModal, setEditShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewShootingMode("");
  };

  const getData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/shootingmodes.php"
      );
      const data = await response.json();
      setShootingmodes(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (showModal) {
      shootingmodesNameInputRef.current.focus();
    }
    fetchCategories();
  }, [showModal]);

  const handleProductSelect = (selectedOptions) => {
    const productIds = selectedOptions.map((item) => item.value);
    const productvalue = selectedOptions.map((item) => item.label);
    console.log({ productIds: productvalue });
    setSelectedcategory(selectedOptions);
    setCategoryid(productvalue);
    setCategoryid1(productIds);

    console.log({ object: productIds });
  };

  const getCategoryLabels = (category_id) => {
    const categoryIds =
      typeof category_id === "string" ? category_id.split(",") : [category_id];
    return category
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const getCategoryLabels1 = (categoryValue) => {
    if (!categoryValue) return [];

    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",")
        : categoryValue;
    return category.filter((item) =>
      categoryIds.includes(item.value.toString())
    );
  };
  const handleProductSelect1 = (selectedOptions) => {
    setEditShootingMode((prev) => ({
      ...prev,
      category_id: selectedOptions.map((option) => option.value).join(","),
    }));
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setShootingView(data.permission.ShootingView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const fetchCategories = () => {
    return fetch("https://mahalakshmimobiles.com/admin/categoriesmaster.php")
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
        setCategory(options);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  };

  const handleInsert = async () => {
    if (!newShootingMode.trim()) {
      swal("warning", "Please enter Audio Jack", "warning");
      return;
    }
    if (categoryid1.length === 0) {
      swal("Warning", "Please select at least one category", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("shooting_modes", newShootingMode);
    formData.append("category_id", categoryid1);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/shootingmodes.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        getData(); // Refresh the data
        handleCloseModal(); // Close the modal
        setCategoryid1([]); // Clear category id field
        setSelectedcategory([]); // Clear selected category field
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleEdit = async (shootingModeId) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/shootingmodes.php?id=${shootingModeId}`
      );
      if (response.ok) {
        const data = await response.json();
        const shootingModeDetails = data.find(
          (item) => item.id === shootingModeId
        );
        setEditShootingMode(shootingModeDetails); // Set id and shooting_modes
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch Audio Jack");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!editShootingMode.shooting_modes.trim()) {
        swal("warning", "Please enter Audio Jack", "warning");
        return;
      }
      if (editShootingMode.category_id.length === 0) {
        swal("Warning", "Please select at least one category", "warning");
        return;
      }
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/shootingmodes.php?id=${editShootingMode.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shooting_modes: editShootingMode.shooting_modes,
            category_id: editShootingMode.category_id,
          }),
        }
      );

      if (response.ok) {
        console.log("Shooting mode updated successfully!");
        setShootingmodes((prevShootingmodes) =>
          prevShootingmodes.map((shooting_mode) =>
            shooting_mode.id === editShootingMode.id
              ? editShootingMode
              : shooting_mode
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update Audio Jack");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Audio Jack!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/shootingmodes.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Shooting mode deleted successfully!");
          getData(); // Refresh the Audio Jacks after deleting
        } else {
          console.error("Failed to delete Audio Jack");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilteredModes.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const filteredShootingModes = shootingmodes.filter((mode) =>
    mode.shooting_modes.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilteredModes = [...filteredShootingModes].sort(
    (a, b) => b.id - a.id
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilteredModes.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilteredModes.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const [variant, setVariant] = useState([]);

  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(shootingmodes);
  }, [shootingmodes]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) =>
      capacity.shooting_modes.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Audio Jack</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Audio Jack
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div> */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {shootingView.add === true ? (
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add Audio Jack
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO</th>
                  {/* <th style={{ textAlign: "center" }}>Audio Jack</th> */}
                  <th
                    onClick={() => handleSortByColumn("shooting_modes")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Audio Jack
                    {sortColumn === "shooting_modes" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Category Name</th> */}
                  <th
                    onClick={() => handleSortByColumn("category_id")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Category Name
                    {sortColumn === "category_id" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {shootingView.edit === true ||
                  shootingView.delete === true ? (
                    <th style={{ textAlign: "center" }}>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((mode, index) => (
                    <tr key={mode.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {mode.shooting_modes}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {getCategoryLabels(mode.category_id)}
                      </td>
                      {(shootingView.edit || shootingView.delete) && (
                        <td style={{ textAlign: "center" }}>
                          {shootingView.edit && (
                            <button
                              className="btn-edit"
                              onClick={() => handleEdit(mode.id)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          )}
                          {shootingView.delete && (
                            <button
                              className="btn-delete"
                              onClick={() => handleDelete(mode.id)}
                            >
                              <FaTrash />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Audio Jack</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 form-group mb-4 text-start">
                <label className="bold">
                  Audio Jack <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  name="shooting_modes"
                  value={newShootingMode}
                  ref={shootingmodesNameInputRef}
                  onChange={(e) => setNewShootingMode(e.target.value)}
                  className="form-control"
                  placeholder="Enter Audio Jack"
                  required
                />
                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Category "
                  options={category}
                  value={selectedcategory}
                  isMulti
                  onChange={handleProductSelect}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Audio Jack</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 form-group mb-4 text-start">
                <label>
                  Audio Jack <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  name="shooting_modes"
                  value={editShootingMode.shooting_modes}
                  onChange={(e) =>
                    setEditShootingMode({
                      ...editShootingMode,
                      shooting_modes: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Enter Audio Jack"
                  required
                />
                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Products"
                  options={category}
                  value={getCategoryLabels1(editShootingMode.category_id)}
                  isMulti
                  onChange={handleProductSelect1}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Shootingmodes;
