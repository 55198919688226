import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [getprivacyPolicy, setGetprivacyPolicy] = useState("");
  const [isUpdate, setIsUpdate] = useState(false); // Track whether to update or add
  const [id, setId] = useState(null); // State to hold the id of the privacy policy for update
  const [privacypolicyView, setPrivacypolicyView] = useState("");
  useEffect(() => {
    // Fetch existing privacy policy data when component mounts
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/policy.php");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log({ data });
      if (data && data.length > 0) {
        setGetprivacyPolicy(data[0].policy);
        setId(data[0].id); // Assuming 'id' is the primary key of your table
        setIsUpdate(true); // Enable update mode
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePrivacyPolicyChange = (event) => {
    setPrivacyPolicy(event.target.value);
  };

  const handleUpdatePrivacyPolicyChange = (event) => {
    setGetprivacyPolicy(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!privacyPolicy) {
      swal("Warning", "Please enter the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("policy", privacyPolicy);
    formData.append("type", "privacyPolicy");
    formData.append("status", "INSERT");

    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/policy.php", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      fetchPrivacyPolicy();
      swal("Success", "Privacy Policy added!", "success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    if (!getprivacyPolicy) {
      swal("Warning", "Please enter the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("id", id); // Pass the id of the privacy policy to update
    formData.append("policy", getprivacyPolicy);
    formData.append("type", "privacyPolicy");
    formData.append("status", "EDIT");

    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/policy.php?id=${id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      fetchPrivacyPolicy();
      swal("Success", "Privacy Policy updated!", "success");
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update Privacy Policy", "error");
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setPrivacypolicyView(data.permission.PrivacyPolicyView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Privacy Policy</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Privacy Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div>
        <div className="card-cat">
          <div className="cat-left">

          </div>
          <div className="">
            <label className="bold">Privacy Policy <i className="text-danger">*</i></label>
            {isUpdate ? (
              <div>
                <textarea
                  value={getprivacyPolicy}
                  className="form-control mt-3"
                  onChange={handleUpdatePrivacyPolicyChange}
                  rows="15"
                ></textarea>
                {
                  privacypolicyView.edit === true ? (
                    <button onClick={handleUpdate} className="add-category-button">
                      Update Privacy Policy
                    </button>
                  ) : ""
                }

              </div>
            ) : (
              <div>

                <textarea
                  value={privacyPolicy}
                  onChange={handlePrivacyPolicyChange}
                  className="form-control mt-3"
                  placeholder="Enter the Privacy Policy"
                  rows="15"
                  
                ></textarea>
                {
                  privacypolicyView.add === true ? (
                    <button onClick={handleSubmit} className="add-category-button">
                      Add Privacy Policy
                    </button>
                  ) : ""
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
