import React, { useEffect, useState, useRef } from "react";
import "./Vieworder.css";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaHome, FaShoppingBag, FaUser } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import destination from "../Assets/Images/destination.png";
import shoppingbag from "../Assets/Images/shopping-bag.png";
import ReactToPrint from "react-to-print";
import Modal from "react-modal";
import falcon from "../Assets/logo.png";
import { toWords } from "number-to-words";
function Vieworder() {
  const Navigate = useNavigate();
  const [orders, setOrders] = useState("");
  console.log({ orders: orders });
  const modalRef = useRef(null);
  const [shop, setShop] = useState([]);
  const componentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState("0");
  const [taxDetails, setTaxDetails] = useState([]);
  let grandTotal = 0;
  const [selectedDate, setSelectedDate] = useState("");
  const [totalFinalPrice, setTotalFinalPrice] = useState(0);
  console.log(totalFinalPrice);
  const [amountInWords, setAmountInWords] = useState("");
  const location = useLocation();
  const { order_id, user_id, address_id } = location.state;
  console.log("object", order_id, user_id, address_id);

  const fetchOrders = async () => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/admin_single_order.php?order_id=${order_id}&user_id=${user_id}&address_id=${address_id}`
      );
      console.log(
        `https://mahalakshmimobiles.com/admin/admin_single_order.php?order_id=${order_id}&user_id=${user_id}&address_id=${address_id}`
      );
      const data = await response.json();

      setOrders(data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  // console.log(' orders', orders)
  const taxprice =
    orders &&
    orders.product_details.map((v) => {
      return Number(v.Producttax) / 2;
    });

  const getData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/shop.php?id=1"
      );
      const data = await response.json();
      setShop(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [order_id, user_id, address_id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (orders?.product_details) {
      let totalPrice = 0;
      const taxData = [];

      orders.product_details.forEach((product) => {
        const itemPrice = parseFloat(product.actual_price) * parseInt(product.ProductQtytemQty);
        const taxRate = parseFloat(product.tax) ? parseFloat(product.tax) : 0;
        const discountAmount = orders.order_details.discount
          ? orders.order_details.discount
          : 0;
        const taxAmount = (itemPrice * taxRate) / 100;
        console.log({ taxAmount: taxRate });
        const finalPrice = itemPrice * product.ProductQtytemQty + taxAmount;

        totalPrice =
          totals.subtotal - orders?.order_details?.discount ??
          "0" + totals.tax.toFixed(2);

        // Collect tax details
        taxData.push({
          taxRate,
          taxAmount,
          itemPrice,
          finalPrice,
        });
      });

      setTotalFinalPrice(totalPrice);
      setTaxDetails(taxData);
    }
  }, [orders]);

  const openModal = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const savedStatus = localStorage.getItem("order_status");
    if (savedStatus) {
      setOrderStatus(savedStatus);
    }
  }, []);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setOrderStatus(newStatus);

    try {
      const formData = new FormData();
      formData.append("order_id", order_id);
      formData.append("order_status", newStatus);
      console.log({ newStatus: newStatus });

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/orderdetails.php?id=${order_id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Order status updated:", data);
      localStorage.setItem("order_status", newStatus);
      fetchOrders();
    } catch (error) {
      console.error("Failed to update order status:", error);
    }
  };

  const calculateTotals = (products) => {
    return products.reduce(
      (totals, product) => {
        totals.subtotal +=
          parseFloat(product.actual_price) * parseInt(product.ProductQtytemQty);
        totals.discount += parseFloat(product.CouponDiscount);
        totals.tax +=
          parseFloat(product.tax_price || 0) *
          parseInt(product.ProductQtytemQty); // Add product-specific tax if any
        totals.totalPrice += parseFloat(
          product.subtotal -
            product.CouponDiscount +
            product.price +
            product.tax_price || 0
        );
        totals.qty += parseInt(product.ProductQtytemQty);
        return totals;
      },
      { subtotal: 0, discount: 0, tax: 0, totalPrice: 0, qty: 0 }
    );
  };

  const totals = calculateTotals(orders?.product_details || []);
  console.log("totals", totals);
  useEffect(() => {
    const words = toWords(
      (totals.subtotal || 0) +
        (totals.tax || 0) -
        (orders?.order_details?.discount ?? 0)
    );
    setAmountInWords(capitalizeFirstLetters(words));
  }, [totalFinalPrice]);

  // Utility function to capitalize the first letter of each word
  const capitalizeFirstLetters = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  // console.log(orders)
  const handleDateChange = (orderid, userid, date) => {
    console.log("Selected delivery date:", date);
    console.log("User ID:", userid);
    console.log("Order ID:", orderid);

    // Format the date to `DD-MM-YYYY` if necessary
    const formattedDate = new Date(date)
      .toLocaleDateString("en-GB")
      .split("/")
      .join("-");

    // Construct the API URL with parameters
    const apiUrl = `https://mahalakshmimobiles.com/admin/admin_delivery_date_update.php?user_id=${userid}&&order_id=${orderid}&&date=${formattedDate}`;
    console.log(apiUrl);
    // Make the API call
    fetch(apiUrl, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        fetchOrders();
        // Handle the response data if needed
      })
      .catch((error) => {
        console.error("Error updating delivery date:", error);
      });
  };
  console.log("selectedDate", selectedDate);
  useEffect(() => {
    // Check if orders and order_details are defined before accessing order_delivered_date
    if (orders?.order_details?.order_delivered_date) {
      const orderDate = orders.order_details.order_delivered_date;
      console.log("orderDate:", orderDate); // Log the order date

      // Check if the order date matches the expected format "DD-MM-YYYY"
      const dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/; // Regular expression for DD-MM-YYYY
      if (dateFormatRegex.test(orderDate)) {
        // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
        const [day, month, year] = orderDate.split("-");
        const formattedDate = `${year}-${month}-${day}`;

        // Set the formatted date
        setSelectedDate(formattedDate);
      } else {
        // Set fallback if the date is invalid
        setSelectedDate("");
        console.warn("Invalid order delivery date format");
      }
    } else {
      console.warn("order_details or order_delivered_date is missing");
    }
  }, [orders]);

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h2 class="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
          {" "}
          Order Details
        </h2>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Order Details
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="content container-fluid">
        <div class="mb-4">
          <h2 class="h1 mb-0 text-capitalize d-flex align-items-center gap-2"></h2>
        </div>
        <div class="row gy-3" id="printableArea">
          <div class="col-lg-8">
            <div class="card h-100">
              <div class="card-body">
                <div class="d-flex flex-wrap flex-md-nowrap gap-10 justify-content-between mb-4">
                  <div class="d-flex flex-column gap-10">
                    <h4 class="text-capitalize">
                      Order No {orders && orders.order_details.id}
                    </h4>
                    <div class="">
                      {orders && orders.order_details.order_place_date}
                    </div>
                  </div>
                  <div class="text-sm-right flex-grow-1">
                    <div class="d-flex flex-wrap gap-10 justify-content-end">
                      <button
                        class="btn btn-primary"
                        onClick={() => openModal()}
                      >
                        <a>Print Invoice</a>
                      </button>
                    </div>

                    <div class="d-flex flex-column gap-2 mt-3">
                      <div class="order-status d-flex justify-content-sm-end  align-items-center text-capitalize">
                        <span className="title-color">Status: </span>
                        <span className="status-badge">
                          {orders &&
                            orders.order_details.order_status === "2" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Pending
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "1" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2 ">
                                Confirmed
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "3" && (
                              <span className="badge badge-soft-warning font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Packaging
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "4" && (
                              <span className="badge badge-soft-warning font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Out for delivery
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "5" && (
                              <span className="badge badge-soft-success font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Delivered
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "6" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Returned
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "7" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Failed to Deliver
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "8" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                Canceled
                              </span>
                            )}
                          {orders &&
                            orders.order_details.order_status === "9" && (
                              <span className="badge badge-soft-danger font-weight-bold radius-50 d-flex align-items-center py-1 px-2">
                                User Canceled
                              </span>
                            )}
                        </span>
                      </div>
                      <div class="payment-method d-flex justify-content-sm-end gap-10 text-capitalize">
                        <span class="title-color">Payment Method :</span>
                        <strong className="status-badge ">
                          {/* {orders && orders.order_details.payment_method} */}
                          Cash On Delivery
                        </strong>
                      </div>
                      {/* <div class="payment-status d-flex justify-content-sm-end gap-10">
                        <span className="title-color">Payment Status:</span>
                        <span className="text-danger payment-status-span status-badge font-weight-bold">
                          {orders &&
                          orders.order_details.order_delivered_status === "0"
                            ? "Unpaid"
                            : "Other Status"}
                        </span>
                      </div> */}
                      <span class="">
                        <b>
                          Order verification code :{" "}
                          {orders && orders.order_details.id}
                        </b>
                      </span>
                    </div>
                  </div>
                  <div>
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Print Modal"
                      className="Modal"
                    >
                      <div
                        style={{
                          textAlign: "center",
                          height: "100vh",
                          paddingBottom: "100px",
                          overflow: "auto",
                        }}
                      >
                        <div ref={componentRef} className="print-container ">
                          {shop.map((product) => (
                            <div key={product.id} className="shop-item">
                              <div className="row align-items-center m-3">
                                <div className="col-md-3">
                                  <img
                                    src={`https://mahalakshmimobiles.com/admin/image/shop/${product.logo}`}
                                    className="falcon-logo"
                                    alt={product.shop_name}
                                  />
                                </div>
                                <div className="col-md-9">
                                  <div className="content_order">
                                    <h5>
                                      Tax Invoice/Bill of Supply/Cash Memo
                                    </h5>
                                    <h6>(Original for Recipient)</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 m-5">
                                <div className="d-flex flex-row">
                                  <div className="w-50">
                                    <h4>Sold By :</h4>
                                    <h6>
                                      {product.shop_name}
                                      <br />
                                      {product.address}
                                    </h6>
                                    Order No :
                                    {orders && orders.order_details.id}
                                    <br />
                                    Order Date:{" "}
                                    {orders &&
                                      orders.order_details.order_place_date}
                                  </div>
                                  <div className="d-flex justify-content-end w-50">
                                    <div className="w-100">
                                      <div>
                                        {orders && (
                                          <div className="text-end">
                                            <div className="d-flex  align-items-center justify-content-end ">
                                              <h4 className="d-flex ">
                                                Shipping address :
                                              </h4>
                                            </div>
                                            <div className="d-flex flex-column align-items-end">
                                              <p className="text-end">
                                                {
                                                  orders.address_details
                                                    .full_name
                                                }
                                                <br />
                                                {
                                                  orders.address_details
                                                    .land_mark
                                                }
                                                ,{orders.address_details.street}
                                                <br />
                                                {orders.address_details.town},
                                                {orders.address_details.state},
                                                {orders.address_details.pincode}{" "}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      {orders && (
                                        <div className="text-end">
                                          <div className="d-flex  align-items-center justify-content-end ">
                                            <h4 className="d-flex ">
                                              Billing address :
                                            </h4>
                                          </div>
                                          <div className="d-flex flex-column align-items-end">
                                            <p className="text-end">
                                              {orders.address_details.full_name}
                                              <br />
                                              {orders.address_details.land_mark}
                                              ,{orders.address_details.street}
                                              <br />
                                              {orders.address_details.town},
                                              {orders.address_details.state},
                                              {orders.address_details.pincode}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-table">
                                  <div className="table-responsive datatable-custom">
                                    <div className="table-container">
                                      <table className="m-0 w-100">
                                        <thead className="thead-50 text-capitalize">
                                          <tr>
                                            <th style={{ textAlign: "center" }}>
                                              SL
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              Item details
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              Item price(Without Tax)
                                            </th>
                                            {/* <th style={{ textAlign: "center" }}>
                                              Item discount
                                            </th> */}
                                            <th style={{ textAlign: "center" }}>
                                              Tax
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              Total Price
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orders?.product_details?.map(
                                            (product, index) => {
                                              console.log(
                                                "product509",
                                                product
                                              );
                                              // Calculate the final price
                                              const itemPrice = parseFloat(
                                                product.price
                                              );
                                              const taxAmount =
                                                (itemPrice *
                                                  parseFloat(
                                                    product.Producttax
                                                      ? `${product.Producttax}%`
                                                      : "0%"
                                                  )) /
                                                100;
                                              console.log({
                                                taxAmount: taxAmount,
                                              });
                                              const discountAmount = parseFloat(
                                                product.CouponDiscount
                                              );
                                              const finalPrice =
                                                itemPrice *
                                                product.ProductQtytemQty;

                                              const productDetails = `${product.ProductName} - ${product.color} - ${product.size} - ${product.storage}`;

                                              return (
                                                <tr key={product.ProductId}>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <div className="media align-items-center gap-10">
                                                      <div>
                                                        <h6 className="mb-0">
                                                          {productDetails}
                                                        </h6>
                                                        <p className="mb-0">
                                                          Qty:
                                                          {
                                                            product.ProductQtytemQty
                                                          }
                                                        </p>
                                                        <p>
                                                          {product.Free_Product
                                                            ?.length > 0 && (
                                                            <span
                                                              style={{
                                                                color: "red",
                                                                fontSize: 14,
                                                              }}
                                                            >
                                                              Gift:{" "}
                                                              {
                                                                product
                                                                  .Free_Product[0]
                                                                  .product_name
                                                              }
                                                            </span>
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {product.actual_price}
                                                  </td>
                                                  {/* <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {product.CouponDiscount}
                                                  </td> */}
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {product.tax}%
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                      
                                                    }}
                                                  >
                                                    {(
                                                      parseFloat(
                                                        product.price
                                                      ) *
                                                      parseInt(
                                                        product.ProductQtytemQty
                                                      )
                                                    ).toFixed(2)}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}

                                          <tr>
                                            <td colSpan="4">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <span className="amt-in-word">
                                                  <b>&nbsp;Total Price:</b>
                                                  <br />
                                                  &nbsp;
                                                  {amountInWords} only
                                                </span>
                                              </div>
                                            </td>
                                            <td style={{ textAlign: "center", fontWeight: "bold",
                                                color: "black", }}>
                                              {(
                                                totals.subtotal +
                                                  totals.tax -
                                                  orders?.order_details
                                                    ?.discount ?? "0"
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      <table>
                                        <thead>
                                          <tr>
                                            <th rowSpan="2"></th>
                                            {/* <th rowSpan="2">
                                              TAXABLE <br /> VALUE
                                            </th> */}
                                            <th colSpan="2">
                                              Central Tax (CGST)
                                            </th>
                                            <th colSpan="2">
                                              State Tax (SGST)
                                            </th>
                                            <th rowSpan="2">Total Tax</th>
                                          </tr>
                                          <tr>
                                            <td style={{ textAlign: "center" }}>
                                              Rate
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              Amount
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              Rate
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              Amount
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {taxDetails.map(
                                            (taxDetail, index) => (
                                              <tr key={index}>
                                                <td className="width-test"></td>
                                                {/* <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    totals.subtotal -
                                                      orders?.order_details
                                                        ?.discount ??
                                                    "0" + totals.tax
                                                  ).toFixed(2)}
                                                </td> */}
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {taxDetail.taxRate / 2}%
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    taxDetail.taxAmount / 2
                                                  ).toFixed(2)}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {taxDetail.taxRate / 2}%
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    taxDetail.taxAmount / 2
                                                  ).toFixed(2)}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    taxDetail.taxAmount / 2 +
                                                    taxDetail.taxAmount / 2
                                                  ).toFixed(2)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              colSpan={5}
                                            >
                                              Total Tax Amount
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                              colSpan={1}
                                            >
                                             {totals.tax.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <ReactToPrint
                          trigger={() => (
                            <button className="sub-btn-print">Print</button>
                          )}
                          content={() => componentRef.current}
                          documentTitle="Invoice"
                          pageStyle="@media print { @page { size: A4; margin: 20mm; } }"
                          onAfterPrint={() => console.log("Data printed")}
                        />
                        <button
                          className="sub-btn-print close-btn m-2"
                          onClick={() => setIsModalOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </Modal>
                  </div>
                </div>
                <div class="table-responsive datatable-custom">
                  <table class="table fz-12 table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                    <thead class="thead-light thead-50 text-capitalize">
                      <tr>
                        <th style={{ textAlign: "center" }}>SL</th>
                        <th style={{ textAlign: "center" }}>Item details</th>
                        <th style={{ textAlign: "center" }}>
                          Item price(without tax)
                        </th>
                        <th style={{ textAlign: "center" }}>Tax</th>
                        {/* <th style={{ textAlign: "center" }}>Item discount</th> */}
                        <th style={{ textAlign: "center" }}>Total price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.product_details?.map((product, index) => (
                        <tr key={product.ProductId}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            <div className="media align-items-center gap-10">
                              {/* <img
                                src={`https://mahalakshmimobiles.com/admin/images/product/${product.ProductImage}`}
                                alt={product.ProductName}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                              /> */}
                              <div>
                                <h6 className="mb-0">{`${product.ProductName} - ${product.color} - ${product.size} - ${product.storage}`}</h6>
                                <p className="mb-0">
                                  Qty: {product.ProductQtytemQty}
                                </p>
                                <p>
                                  {product.Free_Product?.length > 0 && (
                                    <span
                                      style={{ color: "red", fontSize: 14 }}
                                    >
                                      Gift:{" "}
                                      {product.Free_Product[0].product_name}
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {product.actual_price}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {product.tax}%
                          </td>
                          {/* <td style={{ textAlign: "center" }}>
                            {product.CouponDiscount}
                          </td> */}
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {(
                              parseFloat(product.price) *
                              parseInt(product.ProductQtytemQty)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <hr />
                <div class="row justify-content-md-end mb-3">
                  <div class="col-md-9 col-lg-8">
                    <dl class="row gy-1 text-sm-right">
                      <dt class="col-5 text-capitalize">Sub total</dt>
                      <dd class="col-6 title-color">
                        <strong>{totals.subtotal.toFixed(2)}</strong>
                      </dd>

                      <dt class="col-5 text-capitalize"> discount</dt>
                      <dd className="col-6 title-color">
                        -
                        <strong>
                          {orders?.order_details?.discount ?? "0"}
                        </strong>
                      </dd>

                      <dt class="col-5 text-uppercase">CGST/SGST</dt>
                      <dd class="col-6 title-color">
                        <strong>{totals.tax.toFixed(2)}</strong>
                      </dd>

                      <dt class="col-5">
                        <strong>Total</strong>
                      </dt>
                      <dd class="col-6 title-color">
                        <strong>
                          {(
                            totals.subtotal +
                              totals.tax -
                              orders?.order_details?.discount ?? "0"
                          ).toFixed(2)}
                        </strong>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 d-flex flex-column gap-3">
            <div class="card">
              <div class="card-body text-capitalize d-flex flex-column gap-4">
                <div class="d-flex flex-column align-items-center gap-2">
                  <h4 class="mb-0 text-center">Order &amp; Shipping Info</h4>
                </div>
                <div class="">
                  <label class="font-weight-bold title-color fz-14">
                    Change order status
                  </label>
                  {orders && (
                    <select
                      name="order_status"
                      id="order_status"
                      className="form-select"
                      value={orderStatus}
                      onChange={handleStatusChange}
                      disabled={orders.order_details.order_status === "9"}
                    >
                      <option value=""> Select Status</option>
                      <option value="1"> Confirmed</option>
                      <option value="2"> Pending</option>
                      <option value="3"> Packaging </option>
                      <option className="text-capitalize" value="4">
                        Out for delivery
                      </option>
                      <option value="5"> Delivered </option>
                      <option value="6"> Returned</option>
                      <option value="7"> Failed to Deliver </option>
                      <option value="8"> Canceled </option>
                    </select>
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="font-weight-bold title-color fz-14">
                    Order Delivery Date
                  </label>
                  <input
                    type="date"
                    id="deliveryDate"
                    // className="form-select"
                    value={selectedDate} // Set the initial date value
                    onChange={(event) =>
                      handleDateChange(
                        orders.order_details.id,
                        orders.order_details.user_id,
                        event.target.value
                      )
                    }
                    className="date-picker"
                    style={{
                      marginTop: "8px",
                      padding: "8px",
                      fontSize: "14px",
                    }}
                  />
                </div>
                {/* <div class="d-flex justify-content-between align-items-center gap-10 form-control h-auto flex-wrap">
                  <span class="title-color">Payment status</span>
                  <div class="d-flex justify-content-end min-w-100 align-items-center gap-2">
                    <span class="text--primary font-weight-bold">Unpaid</span>
                    <label class="switcher payment-status-text p-1">
                      <input
                        className="switcher_input payment-status "
                        type="checkbox"
                        name="status"
                        data-id="100208"
                        value="unpaid"
                        id="payment-status-toggle"
                      />
                      <label
                        className="switcher_label"
                        htmlFor="payment-status-toggle"
                      ></label>
                    </label>
                  </div>
                </div> */}
                {/* <ul class="list-unstyled list-unstyled-py-4">
                  <li>
                    <label class="font-weight-bold title-color fz-14">
                      Shipping Method (Company Vehicle )
                    </label>
                    <select
                      className="form-select "
                      name="delivery_type"
                      id="choose_delivery_type"
                    >
                      <option value="0">Choose delivery type</option>
                      <option value="self_delivery">
                        By self delivery man
                      </option>
                      <option value="third_party_delivery">
                        By third party delivery service
                      </option>
                    </select>
                  </li>
                  <li class="choose_delivery_man">
                    <label class="font-weight-bold title-color fz-14">
                      Delivery man
                    </label>
                    <select
                      className="form-select "
                      name="delivery_man_id"
                      id="addDeliveryMan"
                      data-order-id="100208"
                      data-select2-id="addDeliveryMan"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="0" data-select2-id="2">
                        Select Delivery man
                      </option>
                      <option value="1" data-select2-id="3">
                        Demo Deliveryman 1 (0********* )
                      </option>
                      <option value="5" data-select2-id="4">
                        Test test (000000345 )
                      </option>
                      <option value="7" data-select2-id="5">
                        Demo Deliveryman (1********* )
                      </option>
                      <option value="10" data-select2-id="6">
                        Will Smith (1********* )
                      </option>
                    </select>
                  </li>
                </ul> */}
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 className="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Customer information
                  </h4>
                </div>
                {orders && (
                  <div class="media flex-wrap gap-3">
                    <div class=""></div>
                    <div class="media-body d-flex flex-column gap-1">
                      <span class="title-color">
                        <strong>{orders.order_details.user_name}</strong>
                      </span>
                      <span class="title-color">
                        {" "}
                        {/* <strong>32</strong> Orders */}
                      </span>
                      <span class="title-color break-all">
                        <strong>{orders.order_details.user_phone}</strong>
                      </span>
                      <span class="title-color break-all">
                        {orders.order_details.user_email}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 class="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Shipping address
                  </h4>
                </div>
                {orders && (
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <span>Name :</span>
                      <strong>{orders.address_details.full_name}</strong>
                    </div>
                    <div>
                      <span>Contact :</span>
                      <strong>{orders.address_details.mobile_no}</strong>
                    </div>
                    <div>
                      <span>Country :</span>
                      <strong>{orders.address_details.country}</strong>
                    </div>
                    <div>
                      <span>City :</span>
                      <strong>{orders.address_details.town}</strong>
                    </div>
                    <div>
                      <span>Pin code :</span>
                      <strong>{orders.address_details.pincode}</strong>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      <img src={destination} className="img_con-loc" />
                      {`${orders.address_details.street}, ${orders.address_details.town}, ${orders.address_details.state}, ${orders.address_details.pincode}, ${orders.address_details.country}`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="d-flex gap-2 align-items-center justify-content-between mb-4">
                  <h4 class="d-flex gap-2">
                    <div className="icon-container">
                      <FaUser className="user-icon" />
                    </div>
                    Billing address
                  </h4>
                </div>
                {orders && (
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <span>Name :</span>
                      <strong>{orders.address_details.full_name}</strong>
                    </div>
                    <div>
                      <span>Contact :</span>
                      <strong>{orders.address_details.mobile_no}</strong>
                    </div>
                    <div>
                      <span>Country :</span>
                      <strong>{orders.address_details.country}</strong>
                    </div>
                    <div>
                      <span>City :</span>
                      <strong>{orders.address_details.town}</strong>
                    </div>
                    <div>
                      <span>Pin code :</span>
                      <strong>{orders.address_details.pincode}</strong>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      <img src={destination} className="img_con-loc" />
                      {`${orders.address_details.street}, ${orders.address_details.town}, ${orders.address_details.state}, ${orders.address_details.pincode}, ${orders.address_details.country}`}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="d-flex gap-2 mb-4">
                  <div className="icon-container">
                    <FaShoppingBag className="user-icon" />
                  </div>
                  Shop Information
                </h4>
                <div class="media">
                  <div class="mr-3">
                    <img
                      class="avatar rounded avatar-70 img-fit"
                      src={shoppingbag}
                      className="img_con-loc"
                    />
                  </div>
                  {shop &&
                    shop.map((product) => (
                      <div class="media-body d-flex flex-column gap-2">
                        <h5>{product.shop_name}</h5>

                        <span class="title-color">
                          {" "}
                          <strong>{product.phoneno}</strong>
                        </span>
                        <div class="d-flex align-items-start gap-2">
                          <img src={destination} className="img_con-loc" />
                          {product.address}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vieworder;
