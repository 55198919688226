import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function Aboutus() {
  const navigate = useNavigate();
  const [aboutus, setPrivacyPolicy] = useState("");
  const [getaboutus, setGetaboutus] = useState("");
  const [aboutusView, setAboutusView] = useState("");
  console.log({ getaboutus: getaboutus });
  const [isUpdate, setIsUpdate] = useState(false); // Track whether to update or add
  const [id, setId] = useState(null);
  const handlePrivacyPolicyChange = (event) => {
    setPrivacyPolicy(event.target.value);
  };
  useEffect(() => {
    // Fetch existing privacy policy data when component mounts
    fetchAboutus();
  }, []);

  const fetchAboutus = async () => {
    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/aboutus.php");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log({ data });
      if (data && data.length > 0) {
        setGetaboutus(data[0].policy);
        setId(data[0].id); // Assuming 'id' is the primary key of your table
        setIsUpdate(true); // Enable update mode
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdatePrivacyPolicyChange = (event) => {
    setGetaboutus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!aboutus) {
      swal("Warning", "Please enter the details", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("policy", aboutus);
    formData.append("type", "aboutus");
    formData.append("status", "INSERT");
    try {
      const response = await fetch("https://mahalakshmimobiles.com/admin/policy.php", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log(data);

      // Assuming your PHP script returns some JSON like { count: 1 } or similar
      const parsedData = JSON.parse(data);
      if (parsedData.count === 1) {
        // Show update button logic
        swal("Success", "About Us updated!", "success");
      } else {
        // Show add button logic
        swal("Success", "About Us added!", "success");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to submit About Us", "error");
    }
  };
  const handleUpdate = async (event) => {
    event.preventDefault();

    if (!getaboutus) {
      swal("Warning", "Please enter the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("id", id); // Pass the id of the privacy policy to update
    formData.append("policy", getaboutus);
    formData.append("type", "aboutus");
    formData.append("status", "EDIT");

    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/policy.php?id=${id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      fetchAboutus();
      swal("Success", "Privacy Policy updated!", "success");
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update Privacy Policy", "error");
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setAboutusView(data.permission.AboutPolicyView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">About Us</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  About Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="card-cat">
        <div className="cat-left">

        </div>
        <div className="">
          <label className="bold"> About Us <i className="text-danger">*</i></label>
          {isUpdate ? (
            <div>
              <textarea
                value={getaboutus}
                className="form-control mt-3"
                onChange={handleUpdatePrivacyPolicyChange}
                 rows="15"
              ></textarea>
              {aboutusView.add === true ? (
                <button onClick={handleUpdate} className="add-category-button">
                  Update About Us
                </button>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <textarea
                value={aboutus}
                onChange={handlePrivacyPolicyChange}
                className="form-control mt-3"
                placeholder="Enter the  About Us"
               rows="15"
              ></textarea>
              {aboutusView.add === true ? (
                <button onClick={handleSubmit} className="add-category-button">
                  Add About Us
                </button>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
