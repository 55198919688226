import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
function Cameraresolution() {
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [camera_resolution, setCameraresolution] = useState([]);
  const [newcamera_resolution, setNewcamera_resolution] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const cameraresolutionNameInputRef = useRef(null);
  const [categoryid, setCategoryid] = useState("");
  const [categoryid1, setCategoryid1] = useState("");
  const [cameraresoloutionView, setCameraresoloutionView] = useState("");
  const [category, setCategory] = useState([]);

  const [selectedcategory, setSelectedcategory] = useState([]);

  const [editShowModal, setEditShowModal] = useState(false);
  const [editcamera_resolution, setEditcamera_resolution] = useState({
    id: "",
    camera_resolution: "",
    category_id: "",
  });
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewcamera_resolution("");
  };

  const Getdata = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/cameraresolution.php"
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setCameraresolution(data); // Update camera_resolution state
      } else {
        console.error("Invalid data format:", data);
        setCameraresolution([]); // Set to empty array if data is not an array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setCameraresolution([]); // Set to empty array on error
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (showModal) {
      cameraresolutionNameInputRef.current.focus();
    }
    fetchCategories();
  }, [showModal]);

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/cameraresolution.php?id=${id}`
      );
      if (response.ok) {
        const resolutions = await response.json();
        const resolutionDetails = resolutions.find((item) => item.id === id);
        setEditcamera_resolution(resolutionDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch camera resolution");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProductSelect = (selectedOptions) => {
    const productIds = selectedOptions.map((item) => item.value);
    const productvalue = selectedOptions.map((item) => item.label);
    setSelectedcategory(selectedOptions);
    setCategoryid(productvalue);
    setCategoryid1(productIds);
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
  
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {

    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setCameraresoloutionView(data.permission.CameraView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const getCategoryLabels = (category_id) => {
    const categoryIds =
      typeof category_id === "string" ? category_id.split(",") : [category_id];
    return category
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const getCategoryLabels1 = (categoryValue) => {
    if (!categoryValue) return [];

    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",")
        : categoryValue;
    return category.filter((item) =>
      categoryIds.includes(item.value.toString())
    );
  };
  const handleProductSelect1 = (selectedOptions) => {
    setEditcamera_resolution((prev) => ({
      ...prev,
      category_id: selectedOptions.map((option) => option.value).join(","),
    }));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const fetchCategories = () => {
    return fetch("https://mahalakshmimobiles.com/admin/categoriesmaster.php")
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
        setCategory(options);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  };

  const handleInsert = async () => {
    if (!newcamera_resolution || typeof newcamera_resolution !== "string") {
      swal("warning", "Please enter camera resolution", "warning");
      return;
    }
    if (categoryid1.length === 0) {
      swal("Warning", "Please select at least one category", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("camera_resolution", newcamera_resolution);
    formData.append("category_id", categoryid1);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/cameraresolution.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        Getdata(); // Refresh the data
        handleCloseModal(); // Close the modal
        setCameraresolution(""); // Clear the input field
        setCategoryid1([]); // Clear category id field
        setSelectedcategory([]); // Clear selected category field
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const handleUpdate = async () => {
    try {

      if (!editcamera_resolution.camera_resolution || typeof editcamera_resolution.camera_resolution !== "string") {
        swal("warning", "Please enter camera resolution", "warning");
        return;
      }
      if (editcamera_resolution.category_id.length === 0) {
        swal("Warning", "Please select at least one category", "warning");
        return;
      }
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/cameraresolution.php?id=${editcamera_resolution.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            camera_resolution: editcamera_resolution.camera_resolution,
            category_id: editcamera_resolution.category_id,
          }),
        }
      );

      if (response.ok) {
        setCameraresolution((prevResolutions) =>
          prevResolutions.map((resolution) =>
            resolution.id === editcamera_resolution.id
              ? editcamera_resolution
              : resolution
          )
        );
        setShowModal(false);
        setNewcamera_resolution("");
        setEditShowModal(false); // Close the edit modal
      } else {
        console.error("Failed to update camera resolution");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this cameraresolution!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/cameraresolution.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const filteredCapacities = camera_resolution.filter((capacity) =>
  //   capacity.camera_resolution.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredCapacities = Array.isArray(camera_resolution)
    ? camera_resolution.filter((capacity) =>
      capacity.camera_resolution
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    : [];

  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const [variant, setVariant] = useState([]);

  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {

    setVariant(camera_resolution)
  }, [camera_resolution]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    if (Array.isArray(variant)) {
      const updatedFilteredCapacities = variant.filter((capacity) =>
        capacity.camera_resolution.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCapacities(updatedFilteredCapacities);
    } else {
      console.error("variant is not an array:", variant);
    }
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv"> Front Camera </h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Front Camera 
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
       

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
             <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {cameraresoloutionView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add Front Camera 
            </button>
          ) : (
            ""
          )} */}


          <div className="d-flex align-items-center ms-auto" style={{ padding: "10px" }}>
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
            <div style={{ padding: "10px", marginBottom: "6px" }}>
            {cameraresoloutionView.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Front Camera 
              </button>
            ) : (
              ""
            )}
            </div>
           
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO</th>
                  {/* <th style={{ textAlign: "center" }}>Front Camera </th> */}
                  <th
                    onClick={() => handleSortByColumn("camera_resolution")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Front Camera 
                    {sortColumn === "camera_resolution" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Category Name</th> */}
                  <th
                    onClick={() => handleSortByColumn("category_id")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Category Name
                    {sortColumn === "category_id" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {cameraresoloutionView.add === true ||
                    cameraresoloutionView.delete === true ? (
                    <th style={{ textAlign: "center" }}> Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((capacity, index) => (
                    <tr key={capacity.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.camera_resolution}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {getCategoryLabels(capacity.category_id)}
                      </td>
                      {(cameraresoloutionView.edit === true ||
                        cameraresoloutionView.delete === true) && (
                          <td style={{ textAlign: "center" }}>
                            {cameraresoloutionView.edit === true && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEdit(capacity.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {cameraresoloutionView.delete === true && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(capacity.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Front Camera </h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Front Camera  <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Front Camera "
                value={newcamera_resolution}
                ref={cameraresolutionNameInputRef}
                onChange={(e) => setNewcamera_resolution(e.target.value)}
              />
              <label className="bold">
                Category <i className="text-danger">*</i>
              </label>
              <Select
                id="categorySelect"
                className="form-control"
                placeholder="Select Category "
                options={category}
                value={selectedcategory}
                isMulti
                onChange={handleProductSelect}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Front Camera </h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Front Camera  <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Front Camera "
                value={editcamera_resolution.camera_resolution}
                onChange={(e) =>
                  setEditcamera_resolution({
                    ...editcamera_resolution,
                    camera_resolution: e.target.value,
                  })
                }
              />
              <label className="bold">
                Category <i className="text-danger">*</i>
              </label>
              <Select
                id="categorySelect"
                className="form-control"
                placeholder="Select Category "
                options={category}
                value={getCategoryLabels1(editcamera_resolution.category_id)}
                isMulti
                onChange={handleProductSelect1}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${page === currentPage ? "active" : ""
                }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Cameraresolution;
