import React, { useState, useEffect } from "react";
import "./Offerpage.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import swal from "sweetalert";

function Offerpage() {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offerpageView, setofferpageView] = useState("");
  const [status, setStatus] = useState("1");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [offerPrices, setOfferPrices] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [products, setProducts] = useState([]);
  const [editShowModal, setEditShowModal] = useState(false);
  const [variantPrice, setVariantPrice] = useState("");
  console.log("variantPrice", variantPrice);
  const [filteredOffers, setFilteredOffers] = useState([]);
  console.log("filteredOffers", filteredOffers);

  const [newOffer, setNewOffer] = useState({
    product_name: "",
    price: "",
    offerPrice: "",
    fromDate: "",
    toDate: "",
  });
  // console.log("newOffer",newOffer.fromDate)
  const [editCategory, setEditCategory] = useState({
    id: "",
    product_name: "",
    price: "",
    offerPrice: "",
    fromDate: "",
    toDate: "",
  });
  console.log("editCategory", editCategory);
  const Navigate = useNavigate();
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const sortedFilter = [...filteredOffers].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleInput = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchQuery(inputValue);
    const filtered = offerPrices.filter((offer) =>
      Object.values(offer).some(
        (value) => value && value.toString().toLowerCase().includes(inputValue)
      )
    );
    setFilteredOffers(filtered);
  };

  const handleEditShowModal = () => {
    setEditShowModal(true);
  };
  const handleEditCloseModal = () => {
    setEditShowModal(false);
  };
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const calculateStatus = (startDate, endDate) => {
    const currentDate = new Date();
    const formattedStartDate = parseDate(startDate);
    const formattedEndDate = parseDate(endDate);

    // Format the dates for comparison
    const formattedCurrentDate = formatDate(currentDate);
    const formattedStartDateStr = formatDate(formattedStartDate);
    const formattedEndDateStr = formatDate(formattedEndDate);

    console.log({ formattedStartDate: formattedStartDateStr });
    console.log({ formattedCurrentDate: formattedCurrentDate });
    console.log({ formattedEndDate: formattedEndDateStr });

    // Check if all formatted dates are the same
    if (
      formattedCurrentDate === formattedStartDateStr &&
      formattedCurrentDate === formattedEndDateStr
    ) {
      return "Active";
    } else if (
      currentDate >= formattedStartDate &&
      currentDate <= formattedEndDate
    ) {
      return "Active";
    } else {
      return "Deactive";
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    fetchOfferPrices()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    // Fetch products from the API
    fetch("https://mahalakshmimobiles.com/admin/productapp.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  const fetchOfferPrices = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/offerprice.php"
      );
      const data = await response.json();
      setOfferPrices(data);
      setFilteredOffers(data); // Set filteredOffers initially with all data
    } catch (error) {
      console.error("Error fetching offer prices:", error);
      setErrorMessage("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setofferpageView(data.permission.OfferView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handelDelete = async (offerId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this offer!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/offerprice.php?id=${offerId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          swal("Deleted!", "Offer has been deleted.", "success");
          fetchOfferPrices(); // Refresh the offer prices
        } else {
          console.error("Failed to delete offer");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelClear = () => {
    setNewOffer({
      product_name: "",
      price: "",
      offerPrice: "",
      fromDate: "",
      toDate: "",
    });
    setShowModal(false);
  };

  const handleInsert = async () => {
    // Check if all required fields are filled
    if (
      !selectedProduct ||
      !newOffer.price ||
      !newOffer.offerPrice ||
      !newOffer.fromDate ||
      !newOffer.toDate
    ) {
      swal({
        title: "Warning",
        text: "Please fill in all fields.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    // Function to format date in 'DD-MM-YYYY' format
    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split("-").map(Number);
      return `${String(day).padStart(2, "0")}-${String(month).padStart(
        2,
        "0"
      )}-${year}`;
    };

    const formattedFromDate = formatDate(newOffer.fromDate);
    const formattedToDate = formatDate(newOffer.toDate);

    const formData = new FormData();
    formData.append("product_name", selectedProduct);
    formData.append("price", newOffer.price);
    formData.append("offer_price", newOffer.offerPrice);
    formData.append("from_date", formattedFromDate);
    formData.append("to_date", formattedToDate);

    try {
      // Insert offer price
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/offerprice.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // Update offer price record
        console.log("response", response);

        const updateData = {
          id: selectedVariantId,
          discount_price: newOffer.offerPrice,
          offer_status: "Active",
        };

        try {
          const updateResponse = await fetch(
            `https://mahalakshmimobiles.com/admin/offerpriceupdate.php?id=${selectedVariantId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updateData),
            }
          );

          if (updateResponse.ok) {
            console.log("Offer price record updated successfully");
          } else {
            console.error("Failed to update offer price record");
          }
        } catch (error) {
          console.error("Error:", error);
        }

        // Refresh offer prices and close modal
        fetchOfferPrices();
        setShowModal(false);

        // Clear the form values
        setNewOffer({
          product_name: "",
          price: "",
          offerPrice: "",
          fromDate: "",
          toDate: "",
        });
        setSelectedProduct("");
        setSelectedVariantId("");
      } else {
        console.error("Failed to insert offer price");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      // Function to format date in 'dd-mm-yyyy' format
      const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      // Convert fromDate and toDate to Date objects and then format them
      const formattedFromDate = formatDate(new Date(editCategory.fromDate));
      const formattedToDate = formatDate(new Date(editCategory.toDate));

      // Prepare the data to be sent to the server
      const data = {
        id: editCategory.id,
        product_name: editCategory.product_name,
        price: editCategory.price,
        offer_price: editCategory.offerPrice,
        from_date: formattedFromDate,
        to_date: formattedToDate,
      };

      // Make a PUT request to update the offer
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/offerprice.php?id=${editCategory.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      // Log the response status for debugging
      console.log("Response status:", response.status);

      // Check if the response is successful
      if (response.ok) {
        console.log("Offer updated successfully!");

        // Update the offerPrices state to reflect the changes
        setOfferPrices((prevOfferPrices) =>
          prevOfferPrices.map((offer) =>
            offer.id === editCategory.id ? { ...offer, ...data } : offer
          )
        );

        // Optionally, fetch the offer prices again to refresh the list
        fetchOfferPrices();
        setEditShowModal(false); // Close the edit modal
      } else {
        console.error("Failed to update offer. Status:", response.status);
      }
    } catch (error) {
      console.error("Error updating offer:", error);
    }
  };
  const handleVariantChange = (e) => {
    const selectedValue = e.target.value;
    const [productId, variantId] = selectedValue.split("-");
    setSelectedVariantId(variantId);

    if (!products?.categories) {
      console.error("Categories data is not available.");
      return;
    }

    // Flatten the list of products from all categories
    const allProducts = products.categories.flatMap(
      (category) => category.products || []
    );

    // Find the selected variant and its associated product
    const selectedVariant = allProducts
      .flatMap((product) => product.variants || [])
      .find((variant) => variant.id === variantId);

    console.log("Selected Variant ID:", variantId);
    console.log("Selected Variant:", selectedVariant);

    if (selectedVariant) {
      setVariantPrice(selectedVariant.price); // Update the state with the selected variant's price
      setNewOffer({ ...newOffer, price: selectedVariant.price });
    }

    // Update the selected product names
    // const filteredProducts = allProducts.filter(
    //   (product) =>
    //     product.variants &&
    //     product.variants.some((variant) => variant.id === variantId)
    // );

    const filteredProducts = allProducts.flatMap((product) =>
      product.variants.map((variant) => ({
        id: variant.id,
        label: `${product.product_name} - ${variant.colour}, ${variant.storage}GB Storage, ${variant.variant_size} Ram`,
      }))
    );

    const selectedProduct = filteredProducts.find(
      (product) => product.id === variantId
    );

    setSelectedProduct(selectedProduct ? selectedProduct.label : "");
  };

  const handleEdit = async (id) => {
    try {
      // Fetch offer details by ID
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/offerprice.php?id=${id}`
      );

      if (response.ok) {
        const offers = await response.json();
        const offerDetails = offers.find((item) => item.id === id);

        if (offerDetails) {
          // Function to format date in 'DD-MM-YYYY' format
          const formatDate = (dateStr) => {
            const [year, month, day] = dateStr.split("-").map(Number);
            return `${String(day).padStart(2, "0")}-${String(month).padStart(
              2,
              "0"
            )}-${year}`;
          };

          // Format the dates from server response
          const formattedFromDate = formatDate(offerDetails.from_date);
          const formattedToDate = formatDate(offerDetails.to_date);

          // Update state with offer details
          setEditCategory({
            id: offerDetails.id,
            product_name: offerDetails.product_name,
            price: offerDetails.price,
            offerPrice: offerDetails.offer_price,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
          });

          // Show the edit modal
          handleEditShowModal();
        } else {
          console.error("Offer with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch offer");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getCategoryLabels = (category_id) => {
    // Ensure products is defined and is an array
    if (!products || !Array.isArray(products)) {
      return "No categories available";
    }

    // Ensure category_id is valid and convert it to an array if it's a string
    const categoryIds =
      typeof category_id === "string" ? category_id.split(",") : [category_id];

    // Filter products based on category_id
    const filteredProducts = products
      .filter((product) =>
        product.variants.some((variant) =>
          categoryIds.includes(variant.id.toString())
        )
      )
      .map((product) =>
        product.variants
          .filter((variant) => categoryIds.includes(variant.id.toString()))
          .map((variant) => (
            <option key={variant.id} value={variant.id}>
              {product.product_name} - {variant.colour}, {variant.storage}GB
              Storage, {variant.variant_size} Ram
            </option>
          ))
      );

    // Flatten the array of arrays and return as a list of options
    return filteredProducts.flat();
  };

  const [sortOrder, setSortOrder] = useState("asc");
  const [variant, setVariant] = useState([]);

  useEffect(() => {
    setVariant(offerPrices);
  }, [offerPrices]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.product_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);

  const handleUpdatevarient = (e) => {
    const [productId, variantId] = e.target.value.split('-');
  
    // Find the selected product and variant
    const selectedProduct = products?.categories
      .flatMap((category) => category.products)
      .find((product) => product.id === productId);
  
    const selectedVariant = selectedProduct?.variants.find(
      (variant) => variant.id === variantId
    );
  
    if (selectedProduct && selectedVariant) {
      setEditCategory({
        ...editCategory,
        product_name: `${selectedProduct.product_name} - ${selectedVariant.storage}GB - ${selectedVariant.variant_size} RAM - ${selectedVariant.colour}`
      });
    } else {
      // Handle case where no product or variant is found
      setEditCategory({
        ...editCategory,
        product_name: ''
      });
    }
  };
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Offer Page</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Offer Page
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card-cat">
        <div className="cat-left"></div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {offerpageView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add Offer
            </button>
          ) : (
            ""
            
          )} */}
          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {offerpageView.add === true ? (
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add Offer
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="table-container text-end">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%", textAlign: "center" }}>S.No</th>
                  {/* <th style={{ textAlign: "center" }}>Product Name</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Name
                    {sortColumn === "product_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Price</th> */}
                  <th
                    onClick={() => handleSortByColumn("price")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Price
                    {sortColumn === "price" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Offer Price</th> */}
                  <th
                    onClick={() => handleSortByColumn("offer_price")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Offer Price
                    {sortColumn === "offer_price" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>From Date</th> */}
                  <th
                    onClick={() => handleSortByColumn("from_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    From Date
                    {sortColumn === "from_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>To Date</th> */}
                  <th
                    onClick={() => handleSortByColumn("to_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    To Date
                    {sortColumn === "to_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  {(offerpageView.edit || offerpageView.delete) && (
                    <th style={{ textAlign: "center" }}>Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {(searchQuery ? filteredOffers : filteredCapacitie).length >
                0 ? (
                  (searchQuery ? filteredOffers : filteredCapacitie).map(
                    (offer, index) => (
                      <tr key={offer.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {offer.product_name}
                        </td>
                        <td style={{ textAlign: "center" }}>{offer.price}</td>
                        <td style={{ textAlign: "center" }}>
                          {offer.offer_price}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {offer.from_date}
                        </td>
                        <td style={{ textAlign: "center" }}>{offer.to_date}</td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`status ${
                              calculateStatus(
                                offer.from_date,
                                offer.to_date
                              ) === "Active"
                                ? "active-status"
                                : "deactive-status"
                            }`}
                          >
                            {calculateStatus(offer.from_date, offer.to_date)}
                          </span>
                        </td>
                        {(offerpageView.edit === true ||
                          offerpageView.delete === true) && (
                          <td style={{ textAlign: "center" }}>
                            {offerpageView.edit === true && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEdit(offer.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {offerpageView.delete === true && (
                              <button
                                className="btn-delete"
                                onClick={() => handelDelete(offer.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="8" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Add Offer</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handelClear}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <label className="bold">
              Product Name <i className="text-danger">*</i>
            </label>
            <select
              className="form-select"
              onChange={(e) => {
                setSelectedProduct(e.target.value);
                handleVariantChange(e);
              }}
            >
              <option value="">Select Product</option>
              {products?.categories?.map((category, catIndex) =>
                category.products?.map((product, prodIndex) =>
                  product.variants?.map((variant, varIndex) => (
                    <option
                      key={`${catIndex}-${prodIndex}-${varIndex}`}
                      value={`${product.id}-${variant.id}`} // Assuming variant has an id
                    >
                      {product.product_name} - {variant.storage} -{" "}
                      {variant.variant_size} -{variant.colour}
                    </option>
                  ))
                )
              )}
            </select>
            <label className="bold">
              Price <i className="text-danger">*</i>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Price"
              value={newOffer.price}
              readOnly
            />
            <label className="bold">
              Offer Price <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              placeholder="Enter Offer Price"
              value={newOffer.offerPrice}
              onChange={(e) => {
                // Use a regular expression to allow only numeric characters and periods
                const filteredValue = e.target.value.replace(/[^0-9.]/g, "");
                setNewOffer({ ...newOffer, offerPrice: filteredValue });
              }}
            />

            <label className="bold">
              From Date <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              placeholder="Enter From Date"
              type="date"
              value={newOffer.fromDate}
              onChange={(e) =>
                setNewOffer({ ...newOffer, fromDate: e.target.value })
              }
            />
            <label className="bold">
              To Date <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              placeholder="Enter To Date"
              type="date"
              value={newOffer.toDate}
              onChange={(e) =>
                setNewOffer({ ...newOffer, toDate: e.target.value })
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handelClear}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editShowModal} onHide={handleEditCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>
                Product Name <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                value={editCategory?.product_name
                  || ""}
                // onChange={(e) =>
                //   setEditCategory({
                //     ...editCategory,
                //     product_name: e.target.value,
                //   })
                // }
              />
              <select
               
                className="form-select"
                onChange={(e) => {
                  handleUpdatevarient(e);
                }}
              >
                <option value="">Select Product</option>
                {products?.categories?.map((category, catIndex) =>
                  category.products?.map((product, prodIndex) =>
                    product.variants?.map((variant, varIndex) => (
                      <option
                        key={`${catIndex}-${prodIndex}-${varIndex}`}
                        value={`${product.id}-${variant.id}`} // Assuming variant has an id
                      >
                        {product.product_name} - {variant.storage} -{" "}
                        {variant.variant_size} -{variant.colour}
                      </option>
                    ))
                  )
                )}
              </select>
            </div>
            <div className="form-group">
              <label>
                Price <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                value={editCategory.price}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, price: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>
                Offer Price <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                value={editCategory.offerPrice}
                onChange={(e) => {
                  // Use a regular expression to allow only numeric characters and at most one period
                  const value = e.target.value;
                  // Regular expression to match numeric values with up to two decimal places
                  const regex = /^[0-9]*\.?[0-9]*$/;
                  if (regex.test(value)) {
                    setEditCategory({
                      ...editCategory,
                      offerPrice: value,
                    });
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>
                From Date <i className="text-danger">*</i>
              </label>
              <input
                type="date"
                className="form-control"
                value={editCategory.fromDate}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, fromDate: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>
                To Date <i className="text-danger">*</i>
              </label>
              <input
                type="date"
                className="form-control"
                value={editCategory.toDate}
                onChange={(e) =>
                  setEditCategory({ ...editCategory, toDate: e.target.value })
                }
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Offerpage;
